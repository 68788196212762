import Cookies from 'universal-cookie';
import { PROXY } from './PopConst';
import { clearState } from './PopState';
import { setToolItems, setMenuItems } from './PopActions';
import { popCaption } from './PopSvg';
import { structAttributes } from './PopObjectStruct';
import { selectObjLink, selectObjValues } from './PopApiGlobal';





function getSessionCookie() {
    const cookies = new Cookies();
    return cookies.get('sessionKey');

}

function setSessionCookie(sessionKey) {  
    const cookies = new Cookies();
    
    if (sessionKey !== undefined) 
        cookies.set('sessionKey', sessionKey, { path: '/', sameSite: 'strict', expires: new Date(new Date().getTime() + 30 * 24 * 3600 * 1000) });
}

function deleteSessionCookie() {
    const cookies = new Cookies();
    return cookies.remove('sessionKey');
}


function getLangCookie() {
    const cookies = new Cookies();
    let langId = cookies.get('sessionLang');
    if (langId) return (langId);
    return ("CT");
}

function setLangCookie(langId) {  
    const cookies = new Cookies();
    
    if (langId !== undefined) 
        cookies.set('sessionLang', langId, { path: '/', sameSite: 'strict' });
}


function selectLocation(globalState, globalDispatch) {
    var options = {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0,
      };
      function success(pos) {
        globalState.navigation.currentlocation = { lat: pos.coords.latitude, lng: pos.coords.longitude, accuracy: pos.coords.accuracy, state: "granted", checked: true };
        globalState.navigation.showMapLocation = true;
        console.log('location: ', globalState.navigation.currentlocation);
        globalDispatch( { type: 'update-state', state: globalState });
      }
    
      function errors(err) {
        console.warn(`ERROR(${err.code}): ${err.message}`);
        globalState.navigation.currentlocation = { state: "error", checked: true };
        globalDispatch( { type: 'update-state', state: globalState });
      }
          
    navigator.permissions
    .query({ name: "geolocation" })
    .then(function (result) {
      console.log(result);
      if (result.state === "granted") {
        //If granted then you can directly call your function here
        navigator.geolocation.getCurrentPosition(success, errors, options);
      } else if (result.state === "prompt") {
        //If prompt then the user will be asked to give permission
        navigator.geolocation.getCurrentPosition(success, errors, options);
      } else if (result.state === "denied") {
        /* globalState.navigation.currentlocation = { lat: pos.coords.latitude, lng: pos.coords.longitude, accuracy: pos.coords.accuracy, state: "denied", checked: true }; */
        globalDispatch( { type: 'update-state', state: globalState });
        //If denied then you have to show instructions to enable location
      }
    });
}





 async function selectIp() { /* updated */
    try {
        /*  const fResult =  await fetch(`https://geolocation-db.com/json/f9902210-97f0-11eb-a459-b997d30983f1`); 
            return ( { country: result.country_code, city: result.city, ipAddress: result.IPv4, latitude: result.latitude, longitude: result.longitude }); */
        const fResult =  await fetch("https://api.ipify.org?format=json");
        const result = await fResult.json();
        return ( {  ipAddress: result.ip });
        
        }
        catch(e) { console.log(e); }
 }



 async function selectConfig(langId, globalState, globalDispatch) { 
    let configLang = langId || getLangCookie();
    try {
        const fResult =  await fetch(`${PROXY}/api/config/${configLang}` );
        const result = await fResult.json();
        if (result[0] !== undefined) {
            globalState.navigation.langId = configLang;  
            globalState.config.langs = result[0];
            globalState.config.svgs = result[1];  
            globalState.config.captions = result[2];  
            globalState.config.configs = result[3];  
            globalState.config.configLoaded = true;
            globalDispatch( { type: 'update-state', state: globalState });
        }
    }        
    catch(e) { console.log(e); }
 }

 async function selectConfigLang(globalState, globalDispatch) { 
    if (!globalState.user.userLogged) {
        setLangCookie(globalState.navigation.langId); 
        selectConfig(globalState.navigation.langId, globalState, globalDispatch)
    }
    else {
        try {
            const fResult =  await fetch(`${PROXY}/api/configLang/${globalState.navigation.langId}` );
            const result = await fResult.json();
            if (result[0] !== undefined) {
                setLangCookie(globalState.navigation.langId);
                globalState.config.objTypes = result[0];
                globalState.config.attributes = result[1]; structAttributes(globalState.config.attributes);
                globalState.config.cards = result[2]; globalState.config.cards.filter(card => card.cardParent !== 0).forEach(card => { let parentCard = globalState.config.cards.find(parentCard => parentCard.cardId === card.cardParent); if (parentCard !== undefined) { if (parentCard.childCards === undefined) parentCard.childCards = []; parentCard.childCards.push(card); card.parentCard = parentCard; }});
                globalState.config.attValues = result[3];
                globalState.config.captions = result[4];
                globalState.config.attributes.forEach(att => { if (att.listId !== null) att.attValues = globalState.config.attValues.filter(val => val.listId === att.listId); });
                globalState.config.toolItems.forEach(item => item.itemName = popCaption(globalState.config, item.itemTag).captionLong);
                globalState.config.menuItems.forEach(item => item.itemName = popCaption(globalState.config, item.itemTag).captionLong );
                globalDispatch( { type: 'update-state', state: globalState });
            }
        }        
        catch(e) { console.log(e); }
    }    
 }



function assignSessionResult(globalState, result) {
    Object.assign(globalState.user, { userLogged: true, ...result[1] });
    globalState.navigation.langId =  globalState.user.userLangId;
    globalState.config.objTypes = result[3];
    globalState.config.attributes = result[4]; structAttributes(globalState.config.attributes);
    globalState.config.cards = result[5]; globalState.config.cards.filter(card => card.cardParent !== 0).forEach(card => { let parentCard = globalState.config.cards.find(parentCard => parentCard.cardId === card.cardParent); if (parentCard !== undefined) { if (parentCard.childCards === undefined) parentCard.childCards = []; parentCard.childCards.push(card); card.parentCard = parentCard; }});
    globalState.config.attValues = result[6];   
    globalState.config.captions = result[7];  
    globalState.config.attributes.forEach(att => { if (att.listId !== null) att.attValues = globalState.config.attValues.filter(val => val.listId === att.listId); });
    globalState.config.toolItems = setToolItems();
    globalState.config.menuItems = setMenuItems();
    globalState.config.toolItems.forEach(item => item.itemName = popCaption(globalState.config, item.itemTag).captionLong );
    globalState.config.menuItems.forEach(item => item.itemName = popCaption(globalState.config, item.itemTag).captionLong );
}


async function requestSession(login, password, vCode, langId, globalState, globalDispatch) { 
    const { user, navigation } = globalState;
    try {
        const resultIp = await selectIp();
        const fResult =  await fetch(`${PROXY}/api/session/request/${login}/${password}/${vCode || "null"}/${langId}/${resultIp.ipAddress}/${navigation.currentlocation.lat || resultIp.latitude}/${navigation.currentlocation.lng || resultIp.longitude}` );
        const result = await fResult.json();
        user.validLogin = (result[0][0].validLogin);
        user.validEmail = (result[0][0].validEmail);
        user.verifiedEmail = (result[0][0].verifiedEmail);
        user.sessionKey = result[0][0].sessionKey;
        if (result[0] && result[0][0] && result[0][0].sessionKey && result[1] !== undefined) {
            assignSessionResult(globalState, result);
            setSessionCookie(user.sessionKey);
            setLangCookie(navigation.langId);
            if (navigation.linkToLoad !== null && !navigation.linkLoaded) selectObjLink(navigation.linkToLoad, globalState, globalDispatch)
        }
        globalDispatch( { type: 'update-state', state: globalState });
        
    }
    catch(e) {
        console.log(e);
    }   
 }

 async function confirmSession(globalState, globalDispatch) {
    let sessionKey = getSessionCookie();   
    if (sessionKey === undefined) return; 
    
    try {
        const fResult =  await fetch(`${PROXY}/api/session/confirm/${sessionKey}`);
        const result = await fResult.json();
        if (result[1] !== undefined) {
            assignSessionResult(globalState, result);
            setLangCookie(globalState.navigation.langId)
            if (globalState.navigation.linkToLoad !== null && !globalState.navigation.linkLoaded) selectObjLink(globalState.navigation.linkToLoad, globalState, globalDispatch)
            globalDispatch( { type: 'update-state', state: globalState });
        }
    }
    catch(e) { console.log(e); }
}

   
async function deleteSession(globalState, globalDispatch) {  
    try {
       const fResult =  await fetch(`${PROXY}/api/session/delete/${globalState.user.sessionKey}`);
       const result = await fResult.json();
       if (result !== undefined)
           deleteSessionCookie();
           clearState(globalState);
        /*   globalState.navigation.langId = getLangCookie(); */
           globalDispatch( { type: 'update-state', state: globalState });
       }
       catch(e) { console.log(e); }
}   

async function updateRegister(login, password, vCode, globalState, globalDispatch) {
    const { user, navigation } = globalState;
    try {
        const resultIp = await selectIp();
        const fResult =  await fetch(`${PROXY}/api/register/update/${user.requestKey}/${login}/${password}/${vCode}/${navigation.langId}/${resultIp.ipAddress}/${navigation.currentlocation.lat || resultIp.latitude}/${navigation.currentlocation.lng || resultIp.longitude}` );
        const result = await fResult.json();
        user.validLogin = (result[0][0].validLogin);
        user.validEmail = (result[0][0].validEmail);
        user.verifiedEmail = (result[0][0].verifiedEmail);
        user.sessionKey = result[0][0].sessionKey;
        if (result[0] && result[0][0] && result[0][0].sessionKey && result[1] !== undefined) {
            assignSessionResult(globalState, result);
            setSessionCookie(user.sessionKey);
            setLangCookie(navigation.langId);
            selectObjValues(1, user.userId, globalState, globalDispatch);    
        }
    }
    catch(e) {
        console.log(e);
    }
}

async function updateJoin(login, fullName, password, vCode, globalState, globalDispatch) {
    const { user, navigation } = globalState;
    try {
        const resultIp = await selectIp();
        const fResult =  await fetch(`${PROXY}/api/join/update/${user.requestKey}/${login}/${fullName}/${password}/${vCode}/${navigation.langId}/${resultIp.ipAddress}/${navigation.currentlocation.lat || resultIp.latitude}/${navigation.currentlocation.lng || resultIp.longitude}` );
        const result = await fResult.json();
        user.validLogin = (result[0][0].validLogin);
        user.validEmail = (result[0][0].validEmail);
        user.verifiedEmail = (result[0][0].verifiedEmail);
        user.sessionKey = result[0][0].sessionKey;
        if (result[0] && result[0][0] && result[0][0].sessionKey && result[1] !== undefined) {
            assignSessionResult(globalState, result);
            setSessionCookie(user.sessionKey);
            setLangCookie(navigation.langId);
            selectObjValues(1, user.userId, globalState, globalDispatch);    
        }
    }
    catch(e) {
        console.log(e);
    }
}


async function updateAccess(password, globalState, globalDispatch) {
    const { user, navigation } = globalState;
    try {
        const resultIp = await selectIp();
        const fResult =  await fetch(`${PROXY}/api/access/update/${user.requestKey}/${user.requestLogin}/${password}/${navigation.langId}/${resultIp.ipAddress}/${navigation.currentlocation.lat || resultIp.latitude}/${navigation.currentlocation.lng || resultIp.longitude}` );
        const result = await fResult.json();
        user.validLogin = (result[0][0].validLogin);
        user.validEmail = (result[0][0].validEmail);
        user.verifiedEmail = (result[0][0].verifiedEmail);
        user.sessionKey = result[0][0].sessionKey;
        if (result[0] && result[0][0] && result[0][0].sessionKey && result[1] !== undefined) {
            assignSessionResult(globalState, result);
            setSessionCookie(user.sessionKey);
            setLangCookie(navigation.langId);
            selectObjValues(1, user.userId, globalState, globalDispatch);    
        }
    }
    catch(e) {
        console.log(e);
    }
}



 async function updateServer() { 
    console.log("update server");
    try {
      const fResult =  await fetch(`${PROXY}/api/updateServer`);
      const result = await fResult.json();
      console.log("update result", result);
    }
    catch(e) { console.log(e); }
}



export { setSessionCookie, setLangCookie, selectIp, selectConfig, selectLocation, selectConfigLang, assignSessionResult, requestSession, confirmSession, deleteSession, 
         updateRegister, updateJoin, updateAccess, updateServer }

