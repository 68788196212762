import { deleteAttValues, searchObjAtt2, updateAttInstOrd, requestChange , requestRegister, requestAccess, requestJoin, updateObjValues, updateAttObjChild, updateNewObject, 
         updateFileCrop, selectObjRelated, deleteObject, selectKpiObjects, selectConnections, approveConnections, rejectConnections } from './PopApiGlobal';
import  { selectLocation } from './PopApiUser';
import { deleteObjectValueInst, updateObjectDependentValues, deleteObjChilds, addObjectValueInst, assignObjAttValue, structObjectHeader, structObject, 
         addNewObject, valueChanged, updateListParent, purgeObjAttValues } from './PopObjectStruct';
import { clearNavigation } from './PopState';
import { pop, FRONT_SERVER } from './PopConst';
import { isNull, nullValue, normalizeEmail } from './PopFunction';
import { popCaption } from './PopSvg';



/* Menu Item Use */


function exitItemUse(globalState) {
    return true;
}

/*
function nullItemUse(globalState) {
    return globalState.user.userLogged && globalState.navigation.editMode;
}
*/


function attFunctionUse(objAttVal, editMode)
{
    switch(objAttVal.att.attFunction.code) {
        case "deleteObject": return editMode; 
        case "changeEmail": return (editMode && objAttVal.obj.objEmail); 
        case "joinObject": return editMode; 
        case "sendInvite": return (editMode && !objAttVal.obj.objAuth);
        default: return editMode;
    }
}



function deleteObjClick(objAttVal, globalState, globalDispatch) {
    const { navigation, config } = globalState;   
    objAttVal.nav.selMode = false;
    navigation.showMessage = true;
    navigation.message = { caption: popCaption(config, "confirm-del-obj").captionLong, value: objAttVal.obj.objName, svg: "tick", callback: () => { deleteObject(objAttVal, globalState, globalDispatch); }};
    globalDispatch( { type: 'update-state', state: globalState  });
}

function emailClick(objAttVal, globalState, globalDispatch) {
    const { navigation, config, user } = globalState;  

    navigation.showMessage = true;
    navigation.message = { caption: popCaption(config, "confirm-email-change").captionLong, svg: "tick", callback: () => { requestChange("email", user.userId, navigation.langId, globalState, globalDispatch); }};
    globalDispatch( { type: 'update-state', state: globalState  });              
}

function joinObjClick(objAttVal, globalState, globalDispatch) {
    requestJoin(objAttVal, globalState, globalDispatch);
}

function sendInvite(objAttVal, globalState, globalDispatch) {
    const { navigation, config, user } = globalState;  

    navigation.showMessage = true;
    navigation.message = { caption: popCaption(config, "confirm-register").captionLong, value: objAttVal.obj.objEmail, htmlType: "email", svg: "tick", callback: () => { requestAccess(objAttVal.obj.objId, user.userId, navigation.message.value, globalState, globalDispatch); }};
    globalDispatch( { type: 'update-state', state: globalState  });              
}

function attFunctionClick(objAttVal, globalState, globalDispatch)
{
    switch(objAttVal.att.attFunction.code) {
        case "sendInvite": sendInvite(objAttVal, globalState, globalDispatch); break;
        case "deleteObject": deleteObjClick(objAttVal, globalState, globalDispatch); break;
        case "changeEmail": emailClick(objAttVal, globalState, globalDispatch); break;
        case "joinObject": if (!objAttVal.val.nullValue) navigator.clipboard.writeText(FRONT_SERVER+"/request/join/"+globalState.navigation.langId+"/"+objAttVal.val.attValue); 
                            else joinObjClick(objAttVal, globalState, globalDispatch); break;
        default:
    }
}

function deleteItemUse(globalState) {
    return globalState.user.userLogged && (globalState.navigation.objAttVal !== null) && 
           (globalState.navigation.objAttVal.att.attFunction === null || (globalState.navigation.objAttVal.att.attFunction !== null && globalState.navigation.objAttVal.att.attFunction.optional)) &&
            globalState.navigation.objAttVal.nav.selMode && (globalState.navigation.objAttVal.val.attInst !== 0) && 
           !globalState.navigation.objAttVal.nav.isHeader && (!globalState.navigation.objAttVal.nav.objLink || globalState.navigation.objAttVal.val.objChild) && 
           (globalState.navigation.objAttVal.att.attField !== "objAdmin" || globalState.navigation.objAttVal.val.attInstOrd !== 1); 

}

function lockedItemUse(globalState) {
    return globalState.user.userLogged && (globalState.navigation.objAttVal !== null) && 
           globalState.navigation.objAttVal.nav.selMode &&           
           !globalState.navigation.objAttVal.nav.isHeader &&
           !globalState.navigation.objAttVal.val.nullValue; 

}

function relatedItemUse(globalState) {
    return globalState.user.userLogged && (globalState.navigation.objAttVal !== null) && 
           (globalState.navigation.objAttVal.nav.selMode) && 
           !globalState.navigation.objAttVal.nav.isHeader &&
           (globalState.navigation.objAttVal.val.objChild !== null); 
}


function cancelItemUse(globalState) {
    return (globalState.user.userLogged && globalState.navigation.objAttVal) && 
    ((globalState.navigation.objAttVal.nav.editMode || globalState.navigation.objAttVal.nav.selMode || globalState.navigation.cropPhoto || globalState.navigation.objAttVal.nav.accessMode || globalState.navigation.objAttVal.nav.relatedMode)
     || (globalState.navigation.searchObj && globalState.navigation.searchObj.selMode));
}

function addItemUse(globalState) {
    return globalState.user.userLogged && (globalState.navigation.objAttVal !== null) && 
           globalState.navigation.objAttVal.nav.selMode && globalState.navigation.objAttVal.att.attMultiple && !globalState.navigation.objAttVal.nav.isItem &&
            !globalState.navigation.objAttVal.val.nullValue && !linkAddItemUse(globalState);
}


function selectItemUse(globalState) {
    return globalState.user.userLogged && (globalState.navigation.objAttVal !== null) && 
           globalState.navigation.objAttVal.nav.selMode && 
           globalState.navigation.objAttVal.nav.isItem;
}

function approveRejectItemUse(globalState) {
    return globalState.user.userLogged && 
           globalState.navigation.editMode && 
           (globalState.data.connections.filter(con => con.selMode).length > 0);
}


/*
function verifyItemUse(globalState) {
    return globalState.user.userLogged && (globalState.navigation.objAttVal !== null) && globalState.navigation.objAttVal.nav.selMode &&
           !globalState.navigation.objAttVal.val.nullValue && globalState.navigation.objAttVal.att.htmlType === "email" && 
           !globalState.navigation.objAttVal.val.attVerified;
}
*/

function registerItemUse(globalState) {
    const { navigation, user } = globalState;
    const { objAttVal } = navigation;

    return (user.userLogged && objAttVal && objAttVal.nav.selMode && !objAttVal.val.nullValue && (objAttVal.att.objChildTypeId === pop.objType.person) && isNull(objAttVal.val.objChild));
    
}

function accessItemUse(globalState) {   
    return globalState.user.userLogged && (globalState.navigation.objAttVal !== null) && globalState.navigation.objAttVal.nav.selMode &&
           !globalState.navigation.objAttVal.val.nullValue && globalState.navigation.objAttVal.att.attField === "objAdmin" && 
           !globalState.navigation.objAttVal.obj.objAuth;
}

/*
function changeItemUse(globalState) {   
    return globalState.user.userLogged && (globalState.navigation.objAttVal !== null) && globalState.navigation.objAttVal.nav.selMode &&
           !globalState.navigation.objAttVal.val.nullValue && globalState.navigation.objAttVal.val.attVerified;
}
*/

function emailItemUse(globalState) {   
    return globalState.user.userLogged && (globalState.navigation.objAttVal !== null) && globalState.navigation.objAttVal.nav.selMode &&
           globalState.navigation.objAttVal.att.attField === "objEmail" && !globalState.navigation.objAttVal.val.nullValue && globalState.navigation.objAttVal.val.attVerified;
}


function moveUpItemUse(globalState) {
    return globalState.user.userLogged && (globalState.navigation.objAttVal !== null) && 
           globalState.navigation.objAttVal.nav.selMode && globalState.navigation.objAttVal.nav.isItem && 
           (globalState.navigation.objAttVal.val.attInstOrd > 1) &&
           !globalState.navigation.objAttVal.parentAtt.childAtt[globalState.navigation.objAttVal.val.attInstOrd-2].val.attActive;
}

function moveDownItemUse(globalState) {
    return globalState.user.userLogged && (globalState.navigation.objAttVal !== null) && 
           globalState.navigation.objAttVal.nav.selMode && globalState.navigation.objAttVal.nav.isItem && 
           !globalState.navigation.objAttVal.val.attActive &&
           (globalState.navigation.objAttVal.val.attInstOrd < globalState.navigation.objAttVal.parentAtt.childAtt.length);
}

function cropPhotoUse(globalState) {
    return globalState.navigation.showMedia && 
           globalState.navigation.showMediaDetail && 
           !globalState.navigation.cropPhoto &&
           (globalState.navigation.mediaAttVal !== null) && 
           (globalState.navigation.mediaCrop.width) && 
           (globalState.navigation.mediaCrop.height);
}

function saveCropUse(globalState) {
    return globalState.navigation.showMedia && globalState.navigation.showMediaDetail && globalState.navigation.cropPhoto;
}


function searchItemUse(globalState) {
    return globalState.user.userLogged && (globalState.navigation.objAttVal !== null) && 
        globalState.navigation.objAttVal.nav.selMode && !globalState.navigation.objAttVal.val.nullValue &&
        !isNull(globalState.navigation.objAttVal.att.objTypeEqual) && isNull(globalState.navigation.objAttVal.val.objParentObjId); 
}

function addObjItemUse(globalState) {
    return globalState.user.userLogged && (globalState.navigation.objAttVal !== null) && 
           globalState.navigation.objAttVal.nav.selMode && 
           !isNull(globalState.navigation.objAttVal.att.objChildTypeId) &&
           isNull(globalState.navigation.objAttVal.val.objChild) &&
           !globalState.navigation.objAttVal.nav.isHeader &&
           !nullValue(globalState.navigation.objAttVal.val);
}



function deleteObjItemUse(globalState) {
    return globalState.user.userLogged && globalState.navigation.objAttVal && 
           (globalState.navigation.objAttVal.att.attField === "objAdmin" || globalState.navigation.objAttVal.att.attField === "objAuth") &&
           globalState.navigation.objAttVal.nav.selMode && globalState.navigation.objAttVal.obj.userIsAdmin;
}


function linkAddItemUse(globalState) {
    const { navigation, user } = globalState;
    const { objAttVal, searchObj } = navigation;

    if (linkObjItemUse(globalState)) return false;

    if (user.userLogged && objAttVal && searchObj &&
           objAttVal.nav.selMode && searchObj.selMode && 
           (objAttVal.att.objChildTypeId === searchObj.objTypeId)) {
              if (objAttVal.val.objChild === searchObj.objId) return false;
              if (objAttVal.nav.isItem && (objAttVal.parentAtt.childAtt.find(item => item.val.objChild === searchObj.objId) !== undefined)) return false;
              if (objAttVal.nav.isHeader && (objAttVal.childAtt.find(item => item.val.objChild === searchObj.objId) !== undefined)) return false;
              if (objAttVal.nav.isHeader || objAttVal.att.attMultiple) return true;
              
        }
    else return false;           
}

function linkObjItemUse(globalState) {
    const { navigation, user } = globalState;
    const { objAttVal, searchObj } = navigation;
    if (user.userLogged && objAttVal && searchObj &&
           objAttVal.nav.selMode && searchObj.selMode && 
           (objAttVal.att.objChildTypeId === searchObj.objTypeId)) {
              if (objAttVal.val.objChild !== null || objAttVal.nav.isHeader) return false;
              if (objAttVal.nav.isItem && (objAttVal.parentAtt.childAtt.find(item => item.val.objChild === searchObj.objId) !== undefined)) return false;
              return true;
        }
    else return false;       
}



function defaultItemUse(globalState) {
    return globalState.user.userLogged;
}


function editItemUse(globalState) {
    return globalState.user.userLogged;
}

function newItemUse(globalState) {
    return globalState.user.userLogged && globalState.navigation.editMode && 
           globalState.config.objTypes[globalState.navigation.searchObjType].objTypeId !== pop.objType.person;
}




function savePoiItemUse(globalState) {
    return globalState.user.userLogged && (globalState.navigation.objAttVal !== null) && 
           globalState.navigation.objAttVal.nav.selMode && 
           (globalState.navigation.objAttVal.att.htmlType === 'location') && globalState.navigation.mapPoi;
}

function saveLocationItemUse(globalState) {
    return globalState.user.userLogged && (globalState.navigation.objAttVal !== null) && 
           globalState.navigation.objAttVal.nav.selMode && 
           (globalState.navigation.objAttVal.att.htmlType === 'location') && globalState.navigation.showMapLocation && globalState.navigation.currentlocation;
}



function locationOnItemUse(globalState) {
    return globalState.user.userLogged && globalState.navigation.editMode && globalState.navigation.currentlocation && 
    globalState.navigation.showMap && globalState.navigation.showMapDetail && !globalState.navigation.showMapLocation;
}

function locationOffItemUse(globalState) {
    return globalState.user.userLogged && globalState.navigation.editMode && globalState.navigation.currentlocation && 
    globalState.navigation.showMap && globalState.navigation.showMapDetail && globalState.navigation.showMapLocation;
}

/* Menu Item Actions */

function exitOnClick(globalState, globalDispatch) {
    if (globalState.navigation.objAttVal !== null) {
        globalState.navigation.objAttVal.nav.editMode = false;
        globalState.navigation.objAttVal.nav.selMode = false; 
        globalState.navigation.objAttVal.nav.accessMode = false;
        globalState.navigation.objAttVal.nav.accessEdit = false; 
        globalState.navigation.objAttVal.nav.relatedMode = false; 
        globalState.navigation.objAttVal.nav.relatedEdit = false; 
    }
    if (globalState.navigation.searchObj) globalState.navigation.searchObj.selMode = false;

    globalState.navigation.editMode = false;    
    globalState.navigation.cropPhoto = false;    
    globalState.navigation.showNull = globalState.navigation.editMode;
    setShowNull(globalState);
    globalDispatch( { type: 'update-state', state: globalState });
}

function cancelOnClick(globalState, globalDispatch) {
    if (globalState.navigation.objAttVal) {
        globalState.navigation.objAttVal.nav.editMode = false;
        globalState.navigation.objAttVal.nav.selMode = false;
        globalState.navigation.objAttVal.nav.accessMode = false;
        globalState.navigation.objAttVal.nav.accessEdit = false;
        globalState.navigation.objAttVal.nav.relatedMode = false; 
        globalState.navigation.objAttVal.nav.relatedEdit = false;         
     }
     if (globalState.navigation.searchObj) globalState.navigation.searchObj.selMode = false;

    globalState.navigation.cropPhoto = false; 
    globalDispatch( { type: 'update-state', state: globalState });
}


function editOnClick(globalState, globalDispatch) {
    globalState.navigation.editMode = !globalState.navigation.editMode;    
    globalState.navigation.showMenu = false;
    globalState.navigation.showNull = globalState.navigation.editMode;
    setShowNull(globalState);
    globalDispatch( { type: 'update-state', state: globalState });
}

function newOnClick(globalState, globalDispatch) {
    let object = addNewObject(null, globalState);
    if (object) {
        structObject(object, globalState);
        clearNavigation(globalState);
        globalState.navigation.editMode = true;
        globalState.navigation.showNull = true;
        window.scrollTo(0, 0);  
        globalDispatch( { type: 'update-state', state: globalState  });
    }
}


function mapOnClick(globalState, globalDispatch) {
    if (!globalState.navigation.showMap) {
        globalState.navigation.showMap = true;
        globalState.navigation.showMapDetail = true;
    } else {
        if (!globalState.navigation.showMapDetail) globalState.navigation.showMapDetail = true;   
        else {
            globalState.navigation.showMap = false;
            globalState.navigation.showMapDetail = false;           
        }
    }
    globalState.navigation.showMenu = false;  
    window.scrollTo(0, 0);    
    globalDispatch( { type: 'update-state', state: globalState });
}

function relationOnClick(globalState, globalDispatch) {
    if (!globalState.navigation.showRelation) {
        globalState.navigation.showRelation = true;
        globalState.navigation.showRelationDetail = true;           
        globalState.navigation.showMenu = false;  
        selectObjRelated(globalState, globalDispatch);    
    } else {
        if (!globalState.navigation.showRelationDetail) globalState.navigation.showRelationDetail = true;
        else {
            globalState.navigation.showRelation = false;
            globalState.navigation.showRelationDetail = false;                  
        }
        globalState.navigation.showMenu = false;
        globalDispatch( { type: 'update-state', state: globalState });
    }    
    window.scrollTo(0, 0);    
}

function kpiOnClick(globalState, globalDispatch) {
    if (!globalState.navigation.showKpi) {
        globalState.navigation.showKpi = true;
        globalState.navigation.showKpiDetail = true;           
        globalState.navigation.showMenu = false;  
        selectKpiObjects(globalState, globalDispatch);   
        globalDispatch( { type: 'update-state', state: globalState });
    } else {
        if (!globalState.navigation.showKpiDetail) globalState.navigation.showKpiDetail = true;
        else {
            globalState.navigation.showKpi = false;
            globalState.navigation.showKpiDetail = false;                  
        }
        globalState.navigation.showMenu = false;
        globalDispatch( { type: 'update-state', state: globalState });
    }    
    window.scrollTo(0, 0);    
}

function connectOnClick(globalState, globalDispatch) {
    if (!globalState.navigation.showConnections) {
        selectConnections(globalState, globalDispatch);   
    } else {
        if (!globalState.navigation.showConnectDetail) globalState.navigation.showConnectDetail = true;
        else {
            globalState.navigation.showConnect = false;
            globalState.navigation.showConnectDetail = false;                  
        }
        globalState.navigation.showMenu = false;
        globalDispatch( { type: 'update-state', state: globalState });
    }    
    window.scrollTo(0, 0);    
}




/*
function folderOnClick(globalState, globalDispatch) {
    globalState.navigation.showFolders = !globalState.navigation.showFolders; 
    globalDispatch( { type: 'update-state', state: globalState });
}
*/

function setShowNull(globalState) {
    /* if (globalState.navigation.showNull) toolItems.find(item => item.itemName === 'null').itemSvg = 'hidden'; 
    else toolItems.find(item => item.itemName === 'null').itemSvg = 'show-null'; */
    globalState.data.objects.filter(object => object.editMode).forEach(object => { object.objCards.filter(objCard => ((objCard.attCount > 0) || (objCard.attMandatory > 0)) && (objCard.card.cardUse !== pop.attUse.hidden)).forEach(card => card.showNull = globalState.navigation.showNull)})
}


function deleteOnClick(globalState, globalDispatch) {
    let { objAttVal } = globalState.navigation;
    if (objAttVal === null) return;
    objAttVal = deleteObjectValueInst(objAttVal);

    let promiseDelAtt = new Promise((res, err) => deleteAttValues(objAttVal, res, err)); 
    promiseDelAtt.then(res => {
        purgeObjAttValues(objAttVal.obj.objAttValues);
        objAttVal.nav.editMode = false;
        objAttVal.nav.selMode = false; 
        updateObjectDependentValues(objAttVal);
        structObjectHeader(objAttVal.obj);   
        globalState.navigation.objAttVal = null;
        globalDispatch( { type: 'update-state', state: globalState });        
    })

    

}

function addOnClick(globalState, globalDispatch) {
    let { objAttVal } = globalState.navigation;
    if (objAttVal === null) return;
    objAttVal.nav.editMode = false;
    objAttVal.nav.selMode = false; 
    globalState.navigation.objAttVal = addObjectValueInst(objAttVal);
    globalDispatch( { type: 'update-state', state: globalState });
}

function updateInstOrd(a,b) {
    if (a.attInstOrd !== b) { a.attInstOrd = b; return true; }
    return false;
}

function updateAttActive(a,b) {
    if (a.attActive !== b) { a.attActive = b; return true; }
    return false;
}

function selectOnClick(globalState, globalDispatch) {
    let { objAttVal } = globalState.navigation;
    if (objAttVal === null || !objAttVal.nav.selMode || objAttVal.parentAtt === null || !objAttVal.parentAtt.nav.isHeader) return;
    objAttVal.nav.selMode = false;
    objAttVal.val.changed = updateAttActive(objAttVal.val, !objAttVal.val.attActive);
    if (objAttVal.val.attActive) objAttVal.parentAtt.childAtt.forEach(att => { if (att !== objAttVal) att.val.changed = updateAttActive(att.val, false); });
    updateListParent(objAttVal.parentAtt);
    updateAttInstOrd(objAttVal, globalState, globalDispatch);            
}

function registerOnClick(globalState, globalDispatch) {
    const { navigation, config, user } = globalState;  
    let { objAttVal } = navigation;

    if (!registerItemUse(globalState, globalDispatch)) return;
    
    objAttVal.nav.selMode = false;
    navigation.showMessage = true;
    navigation.message = { caption: popCaption(config, "confirm-register").captionLong, value: "", htmlType: "email", svg: "tick", callback: () => {
        requestRegister(user.userId, user.userName, objAttVal.obj.objTypeId, objAttVal.obj.objId, objAttVal.att.attId, objAttVal.val.attInst, objAttVal.obj.objName, objAttVal.val.attValue, normalizeEmail(navigation.message.value), globalState, globalDispatch);  
    } };
    globalDispatch( { type: 'update-state', state: globalState });   
}



function accessOnClick(globalState, globalDispatch) {
    const { navigation, config, user } = globalState;  
    let { objAttVal } = navigation;

    if (!accessItemUse(globalState, globalDispatch)) return;

    navigation.showMessage = true;
    navigation.message = { caption: popCaption(config, "confirm-register").captionLong, value: objAttVal.obj.objEmail, htmlType: "email", svg: "tick", callback: () => { requestAccess(objAttVal.obj.objId, user.userId, navigation.message.value, globalState, globalDispatch); }};
    globalDispatch( { type: 'update-state', state: globalState  });              
}

/*
function changeOnClick(globalState, globalDispatch) {
    const { navigation, config, user } = globalState;  
    let { objAttVal } = navigation;

    if (!changeItemUse(globalState, globalDispatch)) return;

    navigation.showMessage = true;
    navigation.message = { caption: popCaption(config, "confirm-change").captionLong, value: objAttVal.att.attName, svg: "tick", callback: () => { requestChange(user.userId, objAttVal.att.objTypeId, objAttVal.obj.objId, objAttVal.att.attId, objAttVal.val.attInst, globalState, globalDispatch); }};
    globalDispatch( { type: 'update-state', state: globalState  });              
}
*/

function emailOnClick(globalState, globalDispatch) {
    const { navigation, config, user } = globalState;  

    if (!emailItemUse(globalState, globalDispatch)) return;

    navigation.showMessage = true;
    navigation.message = { caption: popCaption(config, "confirm-email-change").captionLong, svg: "tick", callback: () => { requestChange("email", user.userId, navigation.langId, globalState, globalDispatch); }};
    globalDispatch( { type: 'update-state', state: globalState  });              
}


function moveUpOnClick(globalState, globalDispatch) {
    let { objAttVal } = globalState.navigation;
    if (objAttVal === null || !objAttVal.nav.selMode || !objAttVal.nav.isItem) return;
    
    objAttVal.parentAtt.childAtt.forEach((att, index) => att.val.changed = updateInstOrd(att.val,index + 1));
    let findAtt = objAttVal.parentAtt.childAtt.find(att => att.val.attInstOrd === objAttVal.val.attInstOrd - 1);
    if (findAtt !== undefined) {
        findAtt.val.changed = updateInstOrd(findAtt.val, objAttVal.val.attInstOrd);
        objAttVal.val.changed = updateInstOrd(objAttVal.val, objAttVal.val.attInstOrd - 1);
        objAttVal.parentAtt.childAtt.sort((a,b) => a.val.attInstOrd - b.val.attInstOrd);
    } 
    updateAttInstOrd(objAttVal, globalState, globalDispatch);
}

function moveDownOnClick(globalState, globalDispatch) {
    let { objAttVal } = globalState.navigation;
    if (objAttVal === null || !objAttVal.nav.selMode || !objAttVal.nav.isItem) return;
    
    objAttVal.parentAtt.childAtt.forEach((att, index) => att.val.changed = updateInstOrd(att.val,index + 1));
    let findAtt = objAttVal.parentAtt.childAtt.find(att => att.val.attInstOrd === objAttVal.val.attInstOrd + 1);
    if (findAtt !== undefined) {
        findAtt.val.changed = updateInstOrd(findAtt.val, objAttVal.val.attInstOrd);
        objAttVal.val.changed = updateInstOrd(objAttVal.val, objAttVal.val.attInstOrd + 1);
        objAttVal.parentAtt.childAtt.sort((a,b) => a.val.attInstOrd - b.val.attInstOrd);
    } 
    updateAttInstOrd(objAttVal, globalState, globalDispatch);
    globalDispatch( { type: 'update-state', state: globalState });
}

function lockedOnClick(globalState, globalDispatch) {
    let { objAttVal } = globalState.navigation;
    if (objAttVal === null) return;
    objAttVal.nav.accessMode = !objAttVal.nav.accessMode;
    objAttVal.nav.relatedMode = false;
    globalDispatch( { type: 'update-state', state: globalState });
}

function relatedOnClick(globalState, globalDispatch) {
    let { objAttVal } = globalState.navigation;
    if (objAttVal === null) return;
    objAttVal.nav.relatedMode = !objAttVal.nav.relatedMode;
    objAttVal.nav.accessMode = false;
    globalDispatch( { type: 'update-state', state: globalState });
}


function min(a,b) {
    if (a <= b) return a; else return b;
}

function initMediaCrop(mediaCrop) {
    let newWidth = 0;
    let newHeight = 0;
    if (mediaCrop.crop.square) {
        newWidth = min(mediaCrop.width, mediaCrop.height);
        newHeight = newWidth;
    } else {
        newWidth = mediaCrop.width * 0.9;
        newHeight = mediaCrop.height * 0.9;   
    }
    mediaCrop.crop.top  = (mediaCrop.height - newHeight) / 2;
    mediaCrop.crop.left = (mediaCrop.width - newWidth) / 2;
    mediaCrop.crop.width = newWidth;
    mediaCrop.crop.height = newHeight;
}   

function cropPhotoOnClick(globalState, globalDispatch) {
    const { navigation } = globalState;
    if (!cropPhotoUse(globalState)) return;
    navigation.cropPhoto = true;
    navigation.mediaCrop.crop.square = (navigation.objAttVal.att.attClassName === "profile");
    initMediaCrop(navigation.mediaCrop);
    globalDispatch( { type: 'update-state', state: globalState });
}

function saveCropOnClick(globalState, globalDispatch) {
    if (!saveCropUse(globalState)) return;
    globalState.navigation.cropPhoto = false;
    globalDispatch( { type: 'update-state', state: globalState });
    updateFileCrop(globalState, globalDispatch);
}




function searchOnClick(globalState, globalDispatch) {
    let { objAttVal } = globalState.navigation;

    if (!objAttVal) return;
    if  (!objAttVal.val.nullValue) {
        objAttVal.nav.selMode = false;
        searchObjAtt2(objAttVal.att.objTypeEqual, objAttVal.att.attEqual, objAttVal.val.attValueId ,objAttVal.val.attValue, globalState, globalDispatch);
    }
    
}

function addObject(globalState, globalDispatch) {
    const { navigation, user } = globalState;  
    let { objAttVal } = navigation;
    if (objAttVal === null || objAttVal.val.nullValue) return;   
    let object = addNewObject(objAttVal, globalState);
    structObject(object, globalState);
    let promiseNewObject = new Promise((res, err) => updateNewObject(object, res, err));
    promiseNewObject.then(res => {
        deleteObjChilds(objAttVal);  
        let promiseDelAtt = new Promise((res, err) => deleteAttValues(objAttVal, res, err)); 
        promiseDelAtt.then(res => {
            assignObjAttValue(objAttVal, object);    
            let promiseUpdateAttObjChild = new Promise((res, err) => updateAttObjChild(objAttVal, user, res, err)); 
            promiseUpdateAttObjChild.then(res => {
                clearNavigation(globalState);
                globalState.navigation.editMode = true;
                globalState.navigation.showNull = true;
                window.scrollTo(0, 0);  
                globalDispatch( { type: 'update-state', state: globalState  });
            })
        })
    })
}

function addObjOnClick(globalState, globalDispatch) {   
    const { navigation, config } = globalState;  
    let { objAttVal } = navigation;    
    if (objAttVal === null || objAttVal.val.nullValue) return;   
    objAttVal.nav.selMode = false;
    navigation.showMessage = true;
    navigation.message = { caption: popCaption(config, "confirm-add-obj").captionLong, value: objAttVal.val.attValue, svg: "tick", callback: () => { addObject(globalState, globalDispatch); }};
    globalDispatch( { type: 'update-state', state: globalState  });
}



function deleteObjOnClick(globalState, globalDispatch) {
    const { navigation, config } = globalState;   
    let { objAttVal } = navigation;
   
    objAttVal.nav.selMode = false;
    navigation.showMessage = true;
    navigation.message = { caption: popCaption(config, "confirm-del-obj").captionLong, value: objAttVal.obj.objName, svg: "tick", callback: () => { deleteObject(objAttVal, globalState, globalDispatch); }};
    globalDispatch( { type: 'update-state', state: globalState  });
}



function linkAddOnClick(globalState, globalDispatch) {
    const { navigation } = globalState;  
    let { objAttVal } = navigation;

    objAttVal.nav.selMode = false;
    navigation.objAttVal = addObjectValueInst(objAttVal);
    linkObjOnClick(globalState, globalDispatch)
}

function linkObjOnClick(globalState, globalDispatch) {
    const { navigation, user } = globalState;  
    let { objAttVal, searchObj } = navigation;

    objAttVal.nav.selMode = false;
    searchObj.selMode = false;
    if (window.innerWidth < 750) navigation.showSearchDetail = false; 
    deleteObjChilds(objAttVal);
    let promiseDelAtt = new Promise((res, err) => deleteAttValues(objAttVal, res, err)); 
    promiseDelAtt.then(res => {
        assignObjAttValue(objAttVal, searchObj);  
        let promiseUpdateAttObjChild = new Promise((res, err) => updateAttObjChild(objAttVal, user, res, err)); 
            promiseUpdateAttObjChild.then(res => { 
                globalDispatch( { type: 'update-state', state: globalState  });
            })
    })
}




function savePoiOnClick(globalState, globalDispatch) {
    const { objAttVal, mapPoi } = globalState.navigation;
    let valueLat = { value: mapPoi.lat.toString(), valueId: null };
    let valueLng = { value: mapPoi.lng.toString(), valueId: null };
  
    objAttVal.childAtt[0].val.changed = valueChanged(objAttVal.childAtt[0], valueLat);
    objAttVal.childAtt[1].val.changed = valueChanged(objAttVal.childAtt[1], valueLng);
    objAttVal.nav.selMode = false;
    globalState.navigation.mapPoi = null;
    if (!objAttVal.childAtt[0].val.changed && !objAttVal.childAtt[1].val.changed) globalDispatch( { type: 'update-state', state: globalState  });
    else updateObjValues(objAttVal.childAtt[0], globalState, globalDispatch);
}


function saveLocationOnClick(globalState, globalDispatch) {
    const { objAttVal, currentlocation } = globalState.navigation;
    let valueLat = { value: currentlocation.lat.toString(), valueId: null };
    let valueLng = { value: currentlocation.lng.toString(), valueId: null };
  
    objAttVal.childAtt[0].val.changed = valueChanged(objAttVal.childAtt[0], valueLat);
    objAttVal.childAtt[1].val.changed = valueChanged(objAttVal.childAtt[1], valueLng);
    objAttVal.nav.selMode = false;
    if (!objAttVal.childAtt[0].val.changed && !objAttVal.childAtt[1].val.changed) globalDispatch( { type: 'update-state', state: globalState  });
    else updateObjValues(objAttVal.childAtt[0], globalState, globalDispatch);
}





function addObjItemSvg(globalState) {
    const { navigation, config } = globalState;

    if (!navigation.objAttVal || !navigation.objAttVal.att.objChildTypeId) return null;
    let objType = config.objTypes.find(objType => objType.objTypeId === navigation.objAttVal.att.objChildTypeId);
    if (!objType || !objType.objTypeSvg) return null;
    return ('add-'+objType.objTypeSvg);
}

function newItemSvg(globalState) {
    const { navigation, config } = globalState;
    return ('add-'+config.objTypes[navigation.searchObjType].objTypeSvg);
}

function deleteObjItemSvg(globalState) {
    const { navigation, config } = globalState;

    if (!navigation.objAttVal) return null;
    let objType = config.objTypes.find(objType => objType.objTypeId === navigation.objAttVal.obj.objTypeId);
    if (!objType || !objType.objTypeSvg) return null;
    return ('delete-'+objType.objTypeSvg);
}


function locationOnClick(globalState, globalDispatch) {
    selectLocation(globalState, globalDispatch);
}


function locationOffOnClick(globalState, globalDispatch) {
    globalState.navigation.showMapLocation = false;
    globalDispatch( { type: 'update-state', state: globalState });
}

function approveOnClick(globalState, globalDispatch) {
    approveConnections(globalState, globalDispatch);
}

function rejectOnClick(globalState, globalDispatch) {
    rejectConnections(globalState, globalDispatch);
}



/* Menu configurations */

const setMenuItems = () => [
    { itemTag: 'add-object',   itemSvg: newItemSvg,        itemUse: newItemUse,        itemOnClick: newOnClick,          left: true },
    { itemTag: 'edit',         itemSvg: 'edit',            itemUse: editItemUse,       itemOnClick: editOnClick,         left: true },
    { itemTag: 'place',        itemSvg: 'place',           itemUse: defaultItemUse,    itemOnClick: mapOnClick,          left: true }, 
    { itemTag: 'relation',     itemSvg: 'relation',        itemUse: defaultItemUse,    itemOnClick: relationOnClick,     left: true },
    { itemTag: 'connect',      itemSvg: 'connect',         itemUse: defaultItemUse,    itemOnClick: connectOnClick,      left: true }
    /* ,{ itemTag: 'kpi',          itemSvg: 'kpi',             itemUse: defaultItemUse,    itemOnClick: kpiOnClick,          left: true },*/
  ];
  
  const setToolItems = () => [  
    { itemTag: 'positive',       itemSvg: 'positive',       itemUse: approveRejectItemUse,     itemOnClick: approveOnClick,      itemGroup: 1 },
    { itemTag: 'negative',       itemSvg: 'negative',       itemUse: approveRejectItemUse,      itemOnClick: rejectOnClick,      itemGroup: 1 },
    { itemTag: 'select',        itemSvg: 'select',          itemUse: selectItemUse,      itemOnClick: selectOnClick,      itemGroup: 1 },
 /*   { itemTag: 'verify',      itemSvg: 'verified',        itemUse: verifyItemUse,      itemOnClick: verifyOnClick,      itemGroup: 1 },  */
    { itemTag: 'register',      itemSvg: 'invite-email',    itemUse: registerItemUse,    itemOnClick: registerOnClick,    itemGroup: 1 },
    { itemTag: 'register',      itemSvg: 'invite-email',    itemUse: accessItemUse,      itemOnClick: accessOnClick,      itemGroup: 1 },
    { itemTag: 'change',        itemSvg: 'email',           itemUse: emailItemUse,       itemOnClick: emailOnClick,      itemGroup: 1 },
 /*   { itemTag: 'change',        itemSvg: 'verified',        itemUse: changeItemUse,      itemOnClick: changeOnClick,      itemGroup: 1 }, */
    { itemTag: 'search',        itemSvg: 'search',          itemUse: searchItemUse,      itemOnClick: searchOnClick,      itemGroup: 1 },
    { itemTag: 'add-object',    itemSvg:  addObjItemSvg,    itemUse: addObjItemUse,      itemOnClick: addObjOnClick,      itemGroup: 1 },
    { itemTag: 'delete-object', itemSvg:  deleteObjItemSvg, itemUse: deleteObjItemUse,   itemOnClick: deleteObjOnClick,   itemGroup: 1 },
    { itemTag: 'join-object',   itemSvg: 'join-object',     itemUse: linkObjItemUse,     itemOnClick: linkObjOnClick,     itemGroup: 1 },
    { itemTag: 'join-add',      itemSvg: 'join-add',        itemUse: linkAddItemUse,     itemOnClick: linkAddOnClick,     itemGroup: 1 },
    { itemTag: 'add-inst',      itemSvg: 'add-inst',        itemUse: addItemUse,         itemOnClick: addOnClick,         itemGroup: 1 },
    { itemTag: 'delete',        itemSvg: 'delete',          itemUse: deleteItemUse,      itemOnClick: deleteOnClick,      itemGroup: 1 },
    { itemTag: 'move-up',       itemSvg: 'move-up',         itemUse: moveUpItemUse,      itemOnClick: moveUpOnClick,      itemGroup: 1 },
    { itemTag: 'move-down',     itemSvg: 'move-down',       itemUse: moveDownItemUse,    itemOnClick: moveDownOnClick,    itemGroup: 1 },
    { itemTag: 'edit-crop',     itemSvg: 'edit-crop',       itemUse: cropPhotoUse,       itemOnClick: cropPhotoOnClick,   itemGroup: 1 },
    { itemTag: 'save',          itemSvg: 'save',            itemUse: saveCropUse,        itemOnClick: saveCropOnClick,    itemGroup: 1 },
    { itemTag: 'add-place',     itemSvg: 'add-place',       itemUse: savePoiItemUse,     itemOnClick: savePoiOnClick,     itemGroup: 1},
    { itemTag: 'add-location',  itemSvg: 'add-location',    itemUse: saveLocationItemUse,itemOnClick: saveLocationOnClick,itemGroup: 1},
    { itemTag: 'locked',        itemSvg: 'locked',          itemUse: lockedItemUse,      itemOnClick: lockedOnClick,      itemGroup: 1 },
    { itemTag: 'related',       itemSvg: 'related',         itemUse: relatedItemUse,     itemOnClick: relatedOnClick,     itemGroup: 1 },
    { itemTag: 'location-on',   itemSvg: 'location-on',     itemUse: locationOnItemUse,  itemOnClick: locationOnClick,   itemGroup: 1 },
    { itemTag: 'location-off',  itemSvg: 'location-off',    itemUse: locationOffItemUse, itemOnClick: locationOffOnClick,itemGroup: 1 },
    { itemTag: 'exit',          itemSvg: 'exit',            itemUse: cancelItemUse,      itemOnClick: cancelOnClick,      itemGroup: 1 },
    { itemTag: 'close',         itemSvg: 'close',           itemUse: exitItemUse,        itemOnClick: exitOnClick,        itemGroup: 2 }
  ];



export { setMenuItems, setToolItems, attFunctionUse, attFunctionClick } ;

