
import { PROXY, pop } from './PopConst';
import { clearNavigation } from './PopState';
import { searchParams } from './PopFunction';
import { addObject, closeObject, updateObjectInst, pushObjChilds, updateObjChilds, structObject, structObjectHeader, structObjProfile, structConnections,
         updateAttFileValue, deleteObjChilds, structSearch, filterSearch, structMap, calcRelationMap, updateObjectDependentValues, updateObject } from './PopObjectStruct';









async function searchObjName(objName, objTypeId, globalState, globalDispatch) { 
    const { user } = globalState;
    try {
       const fResult =  await fetch(`${PROXY}/api/searchObj/${objTypeId}/${objName}/${user.userId}/${user.userAuth}` );
       const result = await fResult.json();
       globalState.data.searchObjTypeId = objTypeId;
       globalState.data.searchResults = result[0];
       globalState.data.searchHeader = result[2];
       globalState.navigation.showMenu = false;
       globalState.navigation.showSearch = true;
       globalState.navigation.showSearchDetail = true;
       window.scrollTo(0, 0);  
       globalDispatch( { type: 'update-state', state: globalState  });
    }
    catch(e) {
       console.log(e);
   }     
 }


 async function searchObjAtt(objTypeId, attId, searchValueId, searchValue, globalState, globalDispatch) { 
    const { user } = globalState;
    try {
       const fResult =  await fetch(`${PROXY}/api/searchObjAtt/${objTypeId}/${attId}/${searchValueId}/${searchValue}/${user.userId}/${user.userAuth}` );
       const result = await fResult.json();
       globalState.data.searchObjTypeId = objTypeId;
       globalState.data.searchResults = result[0];
       globalState.data.searchHeader = result[2];
       globalState.navigation.showMenu = false;
       globalState.navigation.showSearch = true;
       globalState.navigation.showSearchDetail = true;
       window.scrollTo(0, 0);  
       globalDispatch( { type: 'update-state', state: globalState  });
    }
    catch(e) {
       console.log(e);
   }     
 }



async function searchObjMultiple(objTypeId, attId, searchValue, globalState, globalDispatch) { 
    const { user, config, data, navigation } = globalState;

    let searchFields = searchParams(objTypeId, config.attributes, searchValue, data.search);
    let searchFieldsSQL = searchFields.filter(field => !field.postFilter);
    
    try {
        const fResult =  await fetch(`${PROXY}/api/searchObjMultiple`, {
            headers: {
                'Content-Type': 'application/json',
                   "Accept": "*/*"
                },
                method: 'POST',
                body: JSON.stringify({ objTypeId:objTypeId, userId: user.userId, userAuth: user.userAuth, searchFields: searchFieldsSQL })
              });
       const result = await fResult.json();
       structSearch(objTypeId, attId, searchValue, config, [result], data.search);
       filterSearch(data.search, searchFields.filter(field => field.postFilter));
       structMap(navigation, data);
      
       navigation.showMenu = false;
       navigation.showSearch = true;
       navigation.showSearchDetail = true;
       window.scrollTo(0, 0);  
       globalDispatch( { type: 'update-state', state: globalState  });
    }
    catch(e) {
       console.log(e);
   }     
 }



 async function searchObjAtt2(objTypeId, attId, searchValueId, searchValue, globalState, globalDispatch) { 
    const { user, config, data, navigation } = globalState;
    try {
       const fResult =  await fetch(`${PROXY}/api/searchObjAtt2/${objTypeId}/${attId}/${searchValueId}/${searchValue}/${user.userId}/${user.userAuth}` );
       const result = await fResult.json();
       structSearch(objTypeId, attId, searchValue, config, result, data.search);
       navigation.showMenu = false;
       navigation.showSearch = true;
       navigation.showSearchDetail = true;
       window.scrollTo(0, 0);  
       globalDispatch( { type: 'update-state', state: globalState  });
    }
    catch(e) {
       console.log(e);
   }     
 }



 /* searchObjChilds/:objTypeId/:objId/:attId/:userId/:userAuth */

 async function searchObjChilds(objAttVal, globalState, globalDispatch) { 
    const { user } = globalState;
    try {
       const fResult =  await fetch(`${PROXY}/api/searchObjChilds/${objAttVal.obj.objTypeId}/${objAttVal.obj.objId}/${objAttVal.att.attId}/${user.userId}/${user.userAuth}` );
       const result = await fResult.json();
       globalState.data.searchObjTypeId = objAttVal.obj.objTypeId;
       globalState.data.searchResults = result[0];
       globalState.data.searchHeader = result[2];
       globalState.navigation.showMenu = false;
       globalState.navigation.showSearch = true;
       globalState.navigation.showSearchDetail = true;
       window.scrollTo(0, 0);  
       globalDispatch( { type: 'update-state', state: globalState  });
    }
    catch(e) {
       console.log(e);
   }     
 }


 async function selectObjValues(objTypeId, objId, globalState, globalDispatch) {
    const { user, navigation, data } = globalState;
    try {
        const fResult =  await fetch(`${PROXY}/api/objValues/${objTypeId}/${objId}/${user.userId}/${user.userAuth}` );
        const result = await fResult.json();
        let objValues = result[0];
        if (objValues.length > 0) {
            clearNavigation(globalState);
            let object = addObject(objTypeId, objId, result[1][0].objRelated, (result[2][0].userIsAdmin !== 0), objValues, data.objects, navigation.maxObjects);
            structObject(object, globalState);
            window.scrollTo(0, 0);  
            globalDispatch( { type: 'update-state', state: globalState  });
        }
    }
    catch(e) {
        console.log(e);
    }
  
}

async function selectObjLink(objLink, globalState, globalDispatch) {
    const { user, navigation, data } = globalState;
    try {
        const fResult =  await fetch(`${PROXY}/api/objLink/${objLink}/${user.userId}/${user.userAuth}` );
        const result = await fResult.json();
        let objValues = result[0];
        if (objValues.length > 0) {
            clearNavigation(globalState);
            let object = addObject(objValues[0].objTypeId, objValues[0].objId, result[1][0].objRelated, (result[2][0].userIsAdmin !== 0), objValues, data.objects, navigation.maxObjects);
            structObject(object, globalState);
            window.scrollTo(0, 0);  
        }
        navigation.linkLoaded = true;
        globalDispatch( { type: 'update-state', state: globalState  });
    }
    catch(e) {
        console.log(e);
    }
  
}

async function selectObjRelated(globalState, globalDispatch) {
    const { user,  data } = globalState;
    try {
        const fResult =  await fetch(`${PROXY}/api/objRelated/${user.userId}` );
        const result = await fResult.json();
        data.objRelated = result[0];
        calcRelationMap(globalState);
        user.objRelatedLoaded = true;
        globalDispatch( { type: 'update-state', state: globalState });
    }
    catch(e) {
        console.log(e);
    }
  
}

async function selectObjChilds(objAttVal, globalState, globalDispatch) {
    const { user } = globalState;
    try {
        const fResult =  await fetch(`${PROXY}/api/objChilds/${objAttVal.obj.objTypeId}/${objAttVal.obj.objId}/${objAttVal.att.attId}/${objAttVal.val.attInst}/${objAttVal.val.objChildTypeId}/${objAttVal.val.objChild}/${user.userId}/${user.userAuth}` );
        const result = await fResult.json();
        let objValues = result[0];
        if (objValues.length > 0) {
           updateObjectInst(objAttVal.obj.objAttValues, objValues); 
           updateObject(objAttVal.obj, objValues);
        }
        globalDispatch( { type: 'update-state', state: globalState  });
    }
    catch(e) {
        console.log(e);
    }
  
}


function objValueAssignParent(objAttVal, objAttValues) {   
    if (objAttVal.nav.isHeader) return;
    if (objAttValues.indexOf(objAttVal) === -1) objAttValues.push(objAttVal);
    if ((objAttVal.val.attInst === 0) && (objAttVal.parentAtt !== null) && (objAttVal.parentAtt.val.attInst === 0)) 
        objValueAssignParent(objAttVal.parentAtt, objAttValues);
}


async function updateNewObject(object, res, err) {
    let objAttValues = object.objAttValues.filter(att => att.val.changed);
    let objValues = objAttValues.map(objAtt => { 
        objAtt.val.changed = false;
        let objValue = { ...objAtt.val, objId: object.objId }; 
        if (objAtt.att.htmlType === 'select') objValue.attValue = null; 
        return (objValue)});
    try {
        const fResult =  await fetch(`${PROXY}/api/update/attValues`, {
            headers: {
                'Content-Type': 'application/json',
                   "Accept": "*/*"
                },
                method: 'POST',
                body: JSON.stringify(objValues)
              });
         const result = await fResult.json();
         if (result.length > 0) updateObjectInst(objAttValues, result);
         res("ok");
         }
     catch(e) {
    console.log(e);
    err(e);
     }     
}


function updateObjChildValue(objAttVal, globalState, globalDispatch) {
    const { user } = globalState;  
    
    deleteObjChilds(objAttVal);
    let promiseDelAtt = new Promise((res, err) => deleteAttValues(objAttVal, res, err)); 
    promiseDelAtt.then(res => {
        let promiseUpdateAttObjChild = new Promise((res, err) => updateAttObjChild(objAttVal, user, res, err)); 
            promiseUpdateAttObjChild.then(res => { 
                globalDispatch( { type: 'update-state', state: globalState  });
            })
    })
}

async function updateObjValues(objAttVal, globalState, globalDispatch) {        
    updateObjectDependentValues(objAttVal);
    structObjectHeader(objAttVal.obj);
    let objAttValues = objAttVal.obj.objAttValues.filter(att => att.val.changed);
    objValueAssignParent(objAttVal, objAttValues);
    let objValues = objAttValues.map(objAtt => { 
        objAtt.val.changed = false;
        let objValue = { ...objAtt.val, objId: objAttVal.obj.objId }; 
        if (objAtt.att.htmlType === 'select') objValue.attValue = null; 
        return (objValue)});
    try {
        const fResult =  await fetch(`${PROXY}/api/update/attValues`, {
            headers: {
                'Content-Type': 'application/json',
                   "Accept": "*/*"
                },
                method: 'POST',
                body: JSON.stringify(objValues)
              });
         const result = await fResult.json();
         if (result.length > 0) updateObjectInst(objAttValues, result);
         globalDispatch( { type: 'update-state', state: globalState  });   
         
         }
     catch(e) {
    console.log(e);
     }
 } 

 async function updateAttObjChild(objAttVal, user, res, err) {    
    let objAttValues = [];
    let objValues = [{...objAttVal.val, objId: objAttVal.obj.objId, userId: user.userId, userAuth: user.userAuth }];  
    try {
        const fResult =  await fetch(`${PROXY}/api/update/attObjChild`, {
            headers: {
                'Content-Type': 'application/json',
                   "Accept": "*/*"
                },
                method: 'POST',
                body: JSON.stringify(objValues)
              });
         const result = await fResult.json();   
         if (result.length > 0) {
            pushObjChilds(objAttVal, objAttValues);
            updateObjChilds(objAttValues, result);
         }
         res("ok")
        }
     catch(e) {
    console.log(e);
    err(e);
     }
 } 



 async function updateAttInstOrd(objAttVal, globalState, globalDispatch) {   
    let objValues = objAttVal.parentAtt.childAtt.filter(att => att.val.changed).map(objAtt => { objAtt.val.changed = false; return({ ...objAtt.val, objId: objAttVal.obj.objId })});
    try {
        const fResult =  await fetch(`${PROXY}/api/update/attInstOrd`, {
            headers: {
                'Content-Type': 'application/json',
                   "Accept": "*/*"
                },
                method: 'POST',
                body: JSON.stringify(objValues)
              });
         await fResult.json();
         globalDispatch( { type: 'update-state', state: globalState  });           
         }
     catch(e) {
    console.log(e);
     }
 } 


async function updateAttAccess(objAttVal, globalState, globalDispatch) {   
    
    try {
        const fResult =  await fetch(`${PROXY}/api/update/attAccess/${objAttVal.obj.objTypeId}/${objAttVal.obj.objId}/${objAttVal.att.attId}/${objAttVal.val.attInst}/${objAttVal.val.attAccess}` );
        await fResult.json();
        globalDispatch( { type: 'update-state', state: globalState  });   
        }
    catch(e) {
   console.log(e);
    }     
}

async function updateAttRelated(objAttVal, globalState, globalDispatch) {   
    
    try {
        const fResult =  await fetch(`${PROXY}/api/update/attRelated/${objAttVal.obj.objTypeId}/${objAttVal.obj.objId}/${objAttVal.att.attId}/${objAttVal.val.attInst}/${objAttVal.val.attRelated}` );
        await fResult.json();
        globalDispatch( { type: 'update-state', state: globalState  });   
        }
    catch(e) {
   console.log(e);
    }     
}


async function deleteObject(objAttVal, globalState, globalDispatch) {    
    if (objAttVal.obj.objTypeId === pop.objType.person && objAttVal.obj.objAuth) requestDelete(objAttVal, globalState, globalDispatch)
    else {
        try {
            const fAttValue =  await fetch(`${PROXY}/api/delete/obj/${objAttVal.obj.objTypeId}/${objAttVal.obj.objId}/${globalState.user.userId}/${globalState.user.userAuth}` );
            const attValue = await fAttValue.json();
            closeObject(objAttVal.obj, globalState.data.objects);
            clearNavigation(globalState);
            globalDispatch( { type: 'update-state', state: globalState  });   
            console.log(attValue);
            }
        catch(e) {
            console.log(e);
        }    
    }
}


async function requestDelete(objAttVal, globalState, globalDispatch) {
    const { user, navigation } = globalState;
    try {
        const fResult =  await fetch(`${PROXY}/api/delete/request/${navigation.langId}/${objAttVal.obj.objTypeId}/${objAttVal.obj.objId}/${user.userId}/${user.userAuth}` );
        await fResult.json();
        clearNavigation(globalState);
        globalDispatch( { type: 'update-state', state: globalState  });          
        }
        catch(e) {
        console.log(e);
    }    
}


async function deleteAttValues(objAttVal, res, err) {  
    let objValues = objAttVal.obj.objAttValues.filter(att => att.val.delete && (att.val.attInst !== 0)).map(objAtt => { 
        return({ ...objAtt.val, htmlType: objAtt.val.objChild ? "null" : objAtt.att.htmlType, objId: objAttVal.obj.objId })});
    if (objValues.length === 0) { 
        res("ok");
        return; }
    try {
            const fResult =  await fetch(`${PROXY}/api/delete/attValues`, {
                headers: {
                    'Content-Type': 'application/json',
                    "Accept": "*/*"
                    },
                    method: 'POST',
                    body: JSON.stringify(objValues)
                });
            await fResult.json();
            res("ok");
            }
        catch(e) {
        console.log(e);
        err(e);
        }
   
}


function updateAttOrder() {};

async function updateFileCrop(globalState, globalDispatch) {
    const { mediaCrop, mediaAttVal } = globalState.navigation;
    const crop = { left: mediaCrop.crop.left / mediaCrop.width, top: mediaCrop.crop.top / mediaCrop.height, width: mediaCrop.crop.width / mediaCrop.width, height: mediaCrop.crop.height / mediaCrop.height };
    const objValue = { ...mediaAttVal.val, ...crop,  };
    try {
        const fResult = await fetch(`${PROXY}/api/update/fileCrop`, {
            headers: {
                'Content-Type': 'application/json',
                   "Accept": "*/*"
                },
             method: 'POST',
             body: JSON.stringify(objValue),
           });
           await fResult.json();
           let now = new Date();       
           mediaAttVal.val.lastUpdate = now.toISOString();
           globalDispatch( { type: 'update-state', state: globalState });       
     }
     catch (e) {
         console.log('hi ha un error', e);
     }


}





async function uploadAttFile(objAttVal, uploadedFile, globalState, globalDispatch) {
    const file = new FormData();
    
    file.append('image', uploadedFile);
    file.append( 'att', JSON.stringify({...objAttVal.val, objId: objAttVal.obj.objId, attClassName: objAttVal.att.attClassName }) );
    /* let dateTime = EXIF.getTag(uploadedFile, "DateTimeOriginal"); */

    try {
       const fResult = await fetch(`${PROXY}/api/uploadFile`, {
            method: 'POST',
            body: file,
          });
          const result = await fResult.json();
          if (result !== undefined) {          
            objAttVal.val.attValue = result.attValue;
            objAttVal.obj.objId = result.objId;
            objAttVal.val.attInst = result.attInst;
            objAttVal.val.lastUpdate = result.lastUpdate;
            objAttVal.val.nullValue = false;
            if (objAttVal.att.attHasChilds > 0) objAttVal.childAtt.forEach(chiAtt => chiAtt.val.attParentInst = result.attInst);   
/* 
 if (result.length > 0) updateObjectInst(objAttValues, result);
                objAttVal.parentAtt.childAtt.forEach(chiAtt => chiAtt.val.attParentInst = objValue.attParentInst);
            if (objAttVal.att.attHasChilds > 0) objAttVal.childAtt.forEach(chiAtt => chiAtt.val.attParentInst = objValue.attInst);  
*/
            updateAttFileValue(objAttVal);
            if (objAttVal.att.attId === objAttVal.obj.objType.attIdProfile) structObjProfile(objAttVal.obj);      
          }
          globalDispatch( { type: 'update-state', state: globalState  });   
    }
    catch (e) {
        console.log('hi ha un error', e);
    }
      
  }


  async function uploadAttFileLink(objAttVal, globalState, globalDispatch) {
    objAttVal.val.changed = false;
    let objValues = [{ ...objAttVal.val, objId: objAttVal.obj.objId, attClassName: objAttVal.att.attClassName }]; 
    
    try {
        const fResult =  await fetch(`${PROXY}/api/uploadFileLink`, {
            headers: {
                'Content-Type': 'application/json',
                   "Accept": "*/*"
                },
                method: 'POST',
                body: JSON.stringify(objValues)
              });
         const result = await fResult.json();
         if (result !== undefined) {          
            objAttVal.val.attValue = result.attValue;

            objAttVal.obj.objId = result.objId;
            objAttVal.val.attInst = result.attInst;
            objAttVal.val.attValue = result.attValue;
            objAttVal.val.lastUpdate = result.lastUpdate;
            objAttVal.val.nullValue = false;
            /*
            updateObjectInst(objAttValues, objValues)
            */
            if (objAttVal.att.attHasChilds > 0) objAttVal.childAtt.forEach(chiAtt => chiAtt.val.attParentInst = result.attInst);   
            updateAttFileValue(objAttVal);
            if (objAttVal.att.attId === objAttVal.obj.objType.attIdProfile) structObjProfile(objAttVal.obj);   
         }
         globalDispatch( { type: 'update-state', state: globalState  });   
         
         }
     catch(e) {
    console.log(e);
     }
  }
/*
 { props.objAttVal.att.attValues.map(opt => {
        return <option key={'val'+opt.valueId} value={opt.valueId}>{opt.valueName}</option> } ) }
*/

  async function selectListValuesObj(objAttVal, globalState, globalDispatch) {   
    try {
        const fResult =  await fetch(`${PROXY}/api/listValuesObj/${pop.objType.person}/${globalState.user.userId}/${objAttVal.att.objChildTypeId}/${objAttVal.att.attEqual}` );
        const result = await fResult.json();
        if (result && result.length > 0)
        {
            objAttVal.val.attValues = result.map(val => { return({ valueId: val.objId, valueName: val.attValue })});
            globalDispatch( { type: 'update-state', state: globalState  });   
        }
        }
    catch(e) {
   console.log(e);
    }     
}

async function requestRegister(userId, userName, objTypeId, objId, attId, attInst, objName, newUserName, newUserEmail, globalState, globalDispatch) {
    const { navigation } = globalState;
    const { objAttVal } = navigation;

    try {
        const fResult =  await fetch(`${PROXY}/api/register/request/${navigation.langId}/${userId}//${userName}/${objTypeId}/${objId}/${attId}/${attInst}/${objName}/${newUserName}/${newUserEmail}` );
        await fResult.json();
        objAttVal.nav.selMode = false;
        globalDispatch( { type: 'update-state', state: globalState  });          
        }
    catch(e) {
   console.log(e);
    }    
}

async function requestJoin(objAttVal, globalState, globalDispatch) {

    try {
        const fResult =  await fetch(`${PROXY}/api/join/request/${objAttVal.att.objTypeId}/${objAttVal.obj.objId}/${objAttVal.att.attId}/${objAttVal.val.attInst}/${objAttVal.att.objChildAttId}/${globalState.user.userId}` );
        const result = await fResult.json();
        if (result && result[0] && result[0][0].requestKey) { objAttVal.val.attValue = result[0][0].requestKey; objAttVal.val.nullValue = false; }
        globalDispatch( { type: 'update-state', state: globalState  });          
        }
    catch(e) {
   console.log(e);
    }    
}




async function requestAccess(objId, userId, email, globalState, globalDispatch) {
    const { navigation } = globalState;
    const { objAttVal } = navigation;

    try {
        const fResult =  await fetch(`${PROXY}/api/access/request/${navigation.langId}/${objId}/${userId}/${email}` );
        await fResult.json();
        if (objAttVal) objAttVal.nav.selMode = false;
        globalDispatch( { type: 'update-state', state: globalState  });          
        }
    catch(e) {
   console.log(e);
    }    
}

async function requestChange(requestType, userId, langId, globalState, globalDispatch) {
    const { navigation } = globalState;
    const { objAttVal } = navigation;    
    try {
        const fResult =  await fetch(`${PROXY}/api/change/request/${requestType}/${langId}/${userId}` );
        await fResult.json();
        if (objAttVal) objAttVal.nav.selMode = false;
        globalDispatch( { type: 'update-state', state: globalState  });          
        }
    catch(e) {
   console.log(e);
    }    
}


async function requestPassword(langId, login, globalState, globalDispatch) {
    try {
        const fResult =  await fetch(`${PROXY}/api/password/request/${langId}/${login}` );
        const result = await fResult.json();
        globalState.user.validEmail = null;
        globalState.user.validLogin = null;
        if (result[0] !== undefined) globalState.user.resetPassword = true;
        globalDispatch( { type: 'update-state', state: globalState  });          
        }
    catch(e) {
        console.log(e);
    }    
}

async function confirmRequest(requestType, requestKey, globalState, globalDispatch) {
    try {
        const fResult =  await fetch(`${PROXY}/api/request/confirm/${requestType}/${requestKey}` );
        const result = await fResult.json();
        if (result[0] !== undefined) {
            Object.assign(globalState.user, { requestConfirmed: (result[0][0].requestConfirmed === 1), ...result[0][0] });        
            globalDispatch( { type: 'update-state', state: globalState });          
            }
        }
        catch(e) {
        globalState.user.requestConfirmed = false;
        globalDispatch( { type: 'update-state', state: globalState });          
        console.log(e);
    }    
}
 

async function updatePassword(requestKey, password, globalState, globalDispatch) {
    try {
        const fResult =  await fetch(`${PROXY}/api/password/update/${requestKey}/${password}` );
        const result = await fResult.json();
        if (result[0] !== undefined) {
            globalState.user.passwordChanged = (result[0][0].passwordChanged === 1);           
            globalDispatch( { type: 'update-state', state: globalState });          
            }
        }
    catch(e) {
        console.log(e);
    }    
}

async function updateEmail(requestKey, vCode, globalState, globalDispatch) {
    try {
        const fResult =  await fetch(`${PROXY}/api/email/update/${requestKey}/${vCode}` );
        const result = await fResult.json();
        if (result[0] !== undefined) {
            globalState.user.emailChanged = (result[0][0].emailChanged === 1);           
            globalDispatch( { type: 'update-state', state: globalState });          
            }
        }
    catch(e) {
        console.log(e);
    }    
}


async function checkNewEmail(langId, newEmail, globalState, globalDispatch) {
    try {
        const fResult =  await fetch(`${PROXY}/api/email/checkNew/${langId}/${newEmail}` );
        const result = await fResult.json();
        if (result) {
            globalState.user.emailValidated = result.sent;
            globalState.user.requestError = result.vError;
            globalState.user.newEmail = newEmail;
            globalDispatch( { type: 'update-state', state: globalState }); }         
        }
    catch(e) {
        console.log(e);
    }    
}


async function updateDelete(requestKey, password, globalState, globalDispatch) {
    try {
        const fResult =  await fetch(`${PROXY}/api/delete/update/${requestKey}/${password}` );
        const result = await fResult.json();
        if (result[0] !== undefined) {
            globalState.user.objDeleted = (result[0][0].objDeleted === 1);           
            globalDispatch( { type: 'update-state', state: globalState });          
            }
        }
    catch(e) {
        console.log(e);
    }    
}


function sumKpi(tot,kpi) {
    let findKpi = tot.find(val => val.objTypeId === kpi.objTypeId && val.relationLevel === null);
    if (findKpi === undefined) { 
        findKpi = { ...kpi, relationLevel: null };
        tot.push(findKpi);
    } else {
        findKpi.count += kpi.count;
        findKpi.weekChg += kpi.weekChg;
        findKpi.monthChg += kpi.monthChg;
        findKpi.yearChg += kpi.yearChg;
        findKpi.weekNew += kpi.weekNew;
        findKpi.monthNew += kpi.monthNew;
        findKpi.yearNew += kpi.yearNew;
    }
    return tot;
}

async function selectKpiObjects(globalState, globalDispatch) {
    try {
        const fResult =  await fetch(`${PROXY}/api/objKpi/${globalState.user.userId}` );
        const result = await fResult.json();
        if (result[0] !== undefined) {
            
            let chart1Totals = result[1].reduce(sumKpi, []);
            result[1] = result[1].concat(chart1Totals);
            globalState.data.kpis = result[0].map(kpi => { return({...kpi, chart: 0}) });
            globalState.data.kpis = globalState.data.kpis.concat(result[1].map(kpi => { return({...kpi, chart: 1}) }));
            globalState.data.kpis.forEach(kpi => {
                let findObjType = globalState.config.objTypes.find(objType => objType.objTypeId === kpi.objTypeId);
                if (findObjType !== undefined) { 
                    kpi.svg = findObjType.objTypeSvg; 
                    kpi.name = findObjType.objTypeName;
                    kpi.short = findObjType.objTypeShort; }
            });
            globalDispatch( { type: 'update-state', state: globalState  });          
            }
        }
    catch(e) {
        console.log(e);
    }    
}


async function selectConnections(globalState, globalDispatch) {
    const { user, config, data, navigation } = globalState;
    try {
        const fResult =  await fetch(`${PROXY}/api/connections2/${user.userId}` );
        const result = await fResult.json();
        if (result[0] !== undefined) {  
            structConnections(config, result[0], data);
            navigation.showMenu = false;
            navigation.showConnect = true;
            navigation.showConnectDetail = true;
            window.scrollTo(0, 0);  
            globalDispatch( { type: 'update-state', state: globalState  });                  
            }
        }
    catch(e) {
        console.log(e);
    }    
}


async function approveConnections(globalState, globalDispatch) {
    const { data } = globalState;

    let connections = data.connections.filter(con => con.selMode);
    if (connections.length > 0) 
    try {
            const fResult =  await fetch(`${PROXY}/api/connections/approve`, {
                headers: {
                    'Content-Type': 'application/json',
                    "Accept": "*/*"
                    },
                    method: 'POST',
                    body: JSON.stringify(connections)
                });
            await fResult.json();
            data.connections = data.connections.filter(con => !con.selMode);
            globalDispatch( { type: 'update-state', state: globalState  });      
            }
        catch(e) {
        console.log(e);
        }
   
}


async function rejectConnections(globalState, globalDispatch) {
    const { data } = globalState;

    let connections = data.connections.filter(con => con.selMode);
    if (connections.length > 0) 
    try {
            const fResult =  await fetch(`${PROXY}/api/connections/reject`, {
                headers: {
                    'Content-Type': 'application/json',
                    "Accept": "*/*"
                    },
                    method: 'POST',
                    body: JSON.stringify(connections)
                });
            await fResult.json();
            data.connections = data.connections.filter(con => !con.selMode);
            globalDispatch( { type: 'update-state', state: globalState  });      
            }
        catch(e) {
        console.log(e);
        }
   
}


export { confirmRequest, searchObjName, searchObjAtt, searchObjAtt2, 
        searchObjMultiple, searchObjChilds, selectObjValues, selectObjLink, selectObjChilds, updateObjValues, updateAttObjChild, updateNewObject, 
        updateAttAccess, updateAttRelated, updateAttOrder, updateAttInstOrd, deleteAttValues, deleteObject, uploadAttFile, uploadAttFileLink, updateFileCrop, 
        selectListValuesObj, selectObjRelated, updateObjChildValue, selectKpiObjects, selectConnections, approveConnections, rejectConnections,
        requestRegister, requestAccess, requestPassword, requestChange, requestJoin, updatePassword, requestDelete, updateDelete, checkNewEmail, updateEmail }