import React, { useContext } from 'react';
import Context from './Context';
import { PopSvg } from './PopSvg'
import { selectObjValues } from './PopApiGlobal'
import { selectConfigLang, deleteSession } from './PopApiUser';
import { FILE_LOCATION } from './PopConst';




function PopMenu() {
    const { globalState, globalDispatch } = useContext(Context);
    const { navigation, user, config } = globalState;

    const onSelect = () => {
        navigation.showMenu = false;
        selectObjValues(1, user.userId, globalState, globalDispatch);    
    }

    const logoutOnClick = () => {
        deleteSession(globalState, globalDispatch);
    }

    const onChangeLang = e => {
        navigation.langId = e.target.value; 
        navigation.showMenu = false; 
        selectConfigLang(globalState, globalDispatch);
        }




    return (
        navigation.showMenu ? (
        <div className = "pop-menu">
            <div className = "pop-menu-options">      
            {config.menuItems.map((item,index) => {
                return ( item.itemUse(globalState) ? 
                <div key={index} className = "pop-menu-item" onClick = {() => item.itemOnClick(globalState, globalDispatch)}>
                     <PopSvg svg={typeof item.itemSvg === "string" ? item.itemSvg : item.itemSvg(globalState)}/>
                    <p>{item.itemName}</p>
                </div>         
                : null)
            }) }
            </div>
            <div className = "pop-menu-user-language"> 
                { user.userLogged && user.userProfile && 
                <img className="pop-user-profile" src={FILE_LOCATION + user.userProfile} alt="profile" onClick={onSelect}></img>  }
                { user.userLogged && !user.userProfile && 
                <PopSvg className="pop-user-svg" svg="person" onClick={onSelect}/>  }                
                <select type = "text" name = "Language" value = {navigation.langId} onChange= {onChangeLang}>
                    { config.langs.map((lang , index)=> {
                    return <option key={index} value={lang.langId}>{lang.langId}</option> } ) }
                </select>
                { user.userLogged && 
                <div className = "pop-menu-item" onClick = {logoutOnClick}>
                    <PopSvg svg="logout"/>
                </div> }
            </div> 
        </div> ): null
    );

}

/* 
if (shortFile) { return (<img src={ FILE_LOCATION + shortFile} alt="profile" onClick={() => props.onClickObject(obj)}></img>) }
                     else           {  return(<PopSvg svg={obj.objType.objTypeSvg}

*/

export default PopMenu;