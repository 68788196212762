import React, { useContext } from 'react';
import Context from './Context';
import { PopSvg, popCaption } from './PopSvg';
import { selectObjValues } from './PopApiGlobal'
import { FILE_LOCATION, pop } from './PopConst';
import { dateTimeToStr } from './PopFunction';



function PopConnectColumnObject(props) {
    return (
        <div className="pop-connect-column" style={{"flexBasis": "20%" }}> 
        { props.objProfile ? 
        <img className = "pop-field-value-large-icon" src={ FILE_LOCATION + props.objProfile } onClick={() => props.onClickObject(props.objTypeId, props.objId)} alt="profile"/> :
        <PopSvg svg={props.objSvg} className="pop-field-value-svg" fill="#86bbd8" onClick={() => props.onClickObject(props.objTypeId, props.objId)}/> }  
        <p>{props.objName}</p>
     
       </div>
    )
}


function PopConnectColumnAttribute(props) {
    return (
        <div className="pop-connect-column" style={props.style}> 
            <p>{props.attName}</p>
       </div>
    )
}



function PopConnect() {
    const { globalState, globalDispatch } = useContext(Context);
    const { navigation, data, config } = globalState;

    const onClickSelect = (con) => {
        con.selMode = !con.selMode;
        globalDispatch( { type: 'update-state', state: globalState });
    }




    const onClickClose = () => {
        navigation.showConnect = false; 
        navigation.showConnectDetail = false; 
        globalDispatch( { type: 'update-state', state: globalState });
    }
    
    const onClickShowHide = () => {
        navigation.showConnectDetail = !navigation.showConnectDetail; 
        globalDispatch( { type: 'update-state', state: globalState });    
    }

    const onClickObject = (objTypeId,objId) => {
        if (window.innerWidth < 750) navigation.showConnectDetail = false; 
        selectObjValues(objTypeId, objId, globalState, globalDispatch)
    }

    return (
        navigation.showConnect ?
        <div className = "pop-connect">
            <div className = "pop-connect-content">
                <div className = "pop-connect-header">
                    <PopSvg svg="connect"/>
                    <h3>{popCaption(config,'connect-list').captionLong}</h3>     
                    <PopSvg svg={navigation.showConnectDetail ? "hide": "show"} onClick= {onClickShowHide}/>
                    <PopSvg svg="close" onClick = {onClickClose}/>
                </div>                     
                { navigation.showConnectDetail && 
                <div className = "pop-connect-scroll">
                    <div className = "pop-connect-results">            
                    { data.connections.map(con => {
                        return (
                        < div key = {con.connectId} className="pop-connect-row">
                            { navigation.editMode &&
                            <div className="pop-connect-column">
                                <PopSvg svg = {con.selMode ? "check" : "uncheck"} fill="#ddd" style={{"width": "25px"}} onClick = {() => onClickSelect(con)}/>
                            </div> }
                            <PopConnectColumnObject objProfile={con.forObjProfile} objSvg={con.forObjSvg} objName={con.forObjName} objId={con.forObjId} objTypeId={con.forObjTypeId} onClickObject={onClickObject}/>
                            <PopConnectColumnAttribute attName = {con.fromAttName} style={{ "flexBasis": "8%"}}/>
                            <div className="pop-connect-column">
                                <PopSvg svg = {"connect"} style={{"width": "35px"}}/>
                            </div>
                            <PopConnectColumnAttribute attName = {con.forAttName} style={{"flexBasis": "8%" }}/>
                            <PopConnectColumnObject objProfile={con.fromObjProfile} objSvg={con.fromObjSvg} objName={con.fromObjName} objId={con.fromObjId} objTypeId={con.fromObjTypeId} onClickObject={onClickObject}/>                   
                            <PopConnectColumnAttribute attName = {popCaption(config, "requested-by-date").captionLong} style={{ "flexBasis": "10%", "color": "#86bbd8",  "fontSize": "12px"}}/>
                            <PopConnectColumnObject objProfile={con.byUserProfile} objSvg={con.byUserSvg} objName={con.byUserName} objId={con.byUserId} objTypeId={pop.objType.person} onClickObject={onClickObject}/>                   
                            <PopConnectColumnAttribute attName = {dateTimeToStr(con.validFrom)} style={{ "flexBasis": "8%"}}/>
                    </div> )
                    })}
                    </div>
                </div> }
            </div>
        </div> : null
    );
}

export default PopConnect;