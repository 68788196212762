// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pop-header-box {
    background: #7CB400;
    height: 50px;
    display: flex;
    flex-direction: row;
    align-items:center;
    justify-content: space-between;
    color: white;
    padding: 0 16px 0 16px;
  }
  

  .pop-header-title {
    flex-basis: 45%;
    display: flex;
    align-items: center;
  }

  .pop-header-title img {
    height: 40px;
    margin: 0 5px 0 5px;
    -webkit-user-select: none;
            user-select: none;
  }

  .pop-header-title svg {
    height: 50px;
    fill: #fff;
  }

  @media (hover: hover) and (pointer: fine) {
    .pop-header-title svg:hover { opacity: 0.5; }
  }


  .pop-header-search {
    background-color: white;
    opacity: 30%;
    height:  40px;
    border-radius: 20px;
    padding: 0 8px 0 8px;
    flex-basis: 55%;
    max-width: 400px;
    display: flex;
    align-items: center;
  }
  
  .pop-header-search input {
    font-weight: var(--font-bold);
    font-size: 16px;
    flex-basis: 100%;
    width: 85%;
    padding-left: 4px;
  }
 
  .pop-header-search svg {
    height: 35px;
    fill: #7CB400;
  }
  
`, "",{"version":3,"sources":["webpack://./src/PopHeader.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,YAAY;IACZ,aAAa;IACb,mBAAmB;IACnB,kBAAkB;IAClB,8BAA8B;IAC9B,YAAY;IACZ,sBAAsB;EACxB;;;EAGA;IACE,eAAe;IACf,aAAa;IACb,mBAAmB;EACrB;;EAEA;IACE,YAAY;IACZ,mBAAmB;IACnB,yBAAiB;YAAjB,iBAAiB;EACnB;;EAEA;IACE,YAAY;IACZ,UAAU;EACZ;;EAEA;IACE,8BAA8B,YAAY,EAAE;EAC9C;;;EAGA;IACE,uBAAuB;IACvB,YAAY;IACZ,aAAa;IACb,mBAAmB;IACnB,oBAAoB;IACpB,eAAe;IACf,gBAAgB;IAChB,aAAa;IACb,mBAAmB;EACrB;;EAEA;IACE,6BAA6B;IAC7B,eAAe;IACf,gBAAgB;IAChB,UAAU;IACV,iBAAiB;EACnB;;EAEA;IACE,YAAY;IACZ,aAAa;EACf","sourcesContent":[".pop-header-box {\r\n    background: #7CB400;\r\n    height: 50px;\r\n    display: flex;\r\n    flex-direction: row;\r\n    align-items:center;\r\n    justify-content: space-between;\r\n    color: white;\r\n    padding: 0 16px 0 16px;\r\n  }\r\n  \r\n\r\n  .pop-header-title {\r\n    flex-basis: 45%;\r\n    display: flex;\r\n    align-items: center;\r\n  }\r\n\r\n  .pop-header-title img {\r\n    height: 40px;\r\n    margin: 0 5px 0 5px;\r\n    user-select: none;\r\n  }\r\n\r\n  .pop-header-title svg {\r\n    height: 50px;\r\n    fill: #fff;\r\n  }\r\n\r\n  @media (hover: hover) and (pointer: fine) {\r\n    .pop-header-title svg:hover { opacity: 0.5; }\r\n  }\r\n\r\n\r\n  .pop-header-search {\r\n    background-color: white;\r\n    opacity: 30%;\r\n    height:  40px;\r\n    border-radius: 20px;\r\n    padding: 0 8px 0 8px;\r\n    flex-basis: 55%;\r\n    max-width: 400px;\r\n    display: flex;\r\n    align-items: center;\r\n  }\r\n  \r\n  .pop-header-search input {\r\n    font-weight: var(--font-bold);\r\n    font-size: 16px;\r\n    flex-basis: 100%;\r\n    width: 85%;\r\n    padding-left: 4px;\r\n  }\r\n \r\n  .pop-header-search svg {\r\n    height: 35px;\r\n    fill: #7CB400;\r\n  }\r\n  \r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
