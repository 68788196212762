const PROXY = process.env.REACT_APP_PROXY;
const FRONT_SERVER = process.env.REACT_APP_FRONT_SERVER;
const FILE_LOCATION = process.env.REACT_APP_SERVER + '/images/';
const SVG_LOCATION = process.env.REACT_APP_SERVER + '/svg/';

const pop = {
    objType: { person: 1, entity: 2, place: 3, event: 5, group: 6, album: 7, connetion: 8},
    userAuth: { admin: 104, user: 105, underage: 106, unverified: 121 },
    attAccess: { public: 16, friend: 1, private: 0 },
    attUse: { hidden: 0, shown: 1, mandatory: 2, action: 4 },
    cardUse: { hidden: 0, shown: 1, mandatory: 2 },
    attFunction: { optional: 1, preferred: 2, validation: 3, action:4 },
    objRel: { scale: 4, profileWidth: 100, objectWidth: 50 }
  };


  

 export { pop, PROXY, FRONT_SERVER, FILE_LOCATION, SVG_LOCATION };