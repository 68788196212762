import React, { useContext, useState } from 'react';
import Context from './Context';
import { PopSvg } from './PopSvg';


function PopMessageBox() {
    const { globalState, globalDispatch } = useContext(Context);
    const { navigation } = globalState;
    const { message } = navigation;
    const [value, setValue] = useState(message.value || "");
   

    const onClickClose = e => {
        e.preventDefault();
        globalState.navigation.showMessage = false;
        globalState.navigation.message = {};
        globalDispatch( { type: 'update-state', state: globalState });
    }

    const onClickConfirm = e => {
        e.preventDefault();
        if (message.htmlType && (value.length > 6)) {
            globalState.navigation.showMessage = false;
            message.value = value;
            message.callback();         
        }
        else if (!message.htmlType) {
            globalState.navigation.showMessage = false;
            message.callback();
        }
    }

    return (
        navigation.showMessage ? 
        <div className = "pop-message">
            <div className = "pop-message-form">
                <form className = "pop-message-field" onSubmit = {onClickConfirm} >
                    { message.svg && <PopSvg svg={message.svg} className = "pop-message-field-svg" onClick = {onClickConfirm}/> }
                    <div className = "pop-message-field-content">
                        { message.caption &&  
                        <div className = "pop-message-field-label">
                            <p>{message.caption}</p> 
                        </div> }
                        { message.htmlType && 
                        <div className = "pop-message-field-value">
                            <input type = {message.htmlType} value = {value} autoFocus onChange={o => setValue(o.target.value)}/> 
                        </div> }   
                        { message.value && !message.htmlType && 
                        <div className = "pop-message-field-value">
                            <p>{message.value}</p> 
                        </div> }                          
                    </div>
                    <PopSvg svg="close" className = "pop-message-field-svg" onClick = {onClickClose}/>
                </form>         
            </div>
        </div> : null
    );

}


export default PopMessageBox;