import React, { useContext } from 'react';
import Context from './Context';
import { SVG_LOCATION } from './PopConst';

function svgPath(tag, svgs) {
    
    let find = svgs.find(svg => svg.svgTag === tag);
    if (find !== undefined) return find.svgCode;
    else return tag;
}

function popCaption(config, tag) {
    return (config.captions.find(cap => cap.captionTag === tag));
}


function PopSvg(props) {
    const { globalState } = useContext(Context);
    const { config } = globalState;    
    return (
        props.svg.includes(".svg") ?
        <img tabIndex = {-1} className = {props.className} src={SVG_LOCATION+props.svg} alt="svg" onClick={props.onClick} style={{ width:"20px"}}/> :
        <svg tabIndex = {-1} className = {props.className} viewBox={props.viewBox || "0 0 24 24"} style={props.style} onClick={props.onClick}>
            { props.fill && <path fill={props.fill} d={svgPath(props.svg, config.svgs)}></path> }          
            { !props.fill && <path d={svgPath(props.svg, config.svgs)}></path> }
        </svg>
    )
}


function svgToStr(svg, config, fill) {
    return ( `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Layer_1" viewBox="0 0 24 24" x="0px" y="0px" height="50px" width="50px"> <path fill="${fill}" d="${svgPath(svg, config.svgs)}"></path> </svg>` )
}

export { PopSvg, popCaption, svgPath, svgToStr }


/*
<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 100 100" enable-background="new 0 0 100 100" xml:space="preserve" height="100px" width="100px"
*/