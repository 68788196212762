import React, { Fragment, useContext, useState } from 'react';
import Context from './Context';
import { PopSvg, popCaption } from './PopSvg';
import { validEmail, normalizeEmail } from './PopFunction';

import { updatePassword, updateDelete, checkNewEmail, updateEmail } from './PopApiGlobal';
import { updateRegister, updateAccess, updateJoin } from './PopApiUser';


function PopMessage(props) {
    const { globalState } = useContext(Context);
    const { config } = globalState;
  
     const onSubmit = e => {
        e.preventDefault();
       /* window.opener = null; */
        window.open("", "_self");
        window.close();   
    }

    return (
        <div className = "pop-login-box">
            <div className = "pop-login-content-box">
                <div className = "pop-login-card-box" >
                    <div className = "pop-login-card-content">
                        <div className = "pop-login-card-header">
                            <PopSvg svg="info"/> 
                            <h3>{popCaption(config, props.msg).captionShort}</h3>      
                        </div>
                        <form className = "pop-login-fields" onSubmit={onSubmit}>
                            <div className = "pop-login-field">
                                <div className = "pop-login-field-message">
                                    <p>{popCaption(config, props.msg).captionLong}</p>
                                </div>
                                <button className="pop-login-field-icon">
                                        <PopSvg svg="leave"/>
                                </button>                        
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div> 
    );
}


function PopPassword() {
    const { globalState } = useContext(Context);
    const { user } = globalState;
    return (
       user.passwordChanged ? 
        <PopMessage msg="change-pass-thanks"/> : 
        <PopChangePassword/>
    )
}

function PopDelete() {
    const { globalState } = useContext(Context);
    const { user } = globalState;
    return (
       user.objDeleted ? 
        <PopMessage msg="delete-thanks"/> : 
        <PopUpdateDelete/>
    )
}

function PopChangePassword() {
    const { globalState, globalDispatch } = useContext(Context);
    const { config, user } = globalState;
    const [password, setPassword] = useState('');
    const [password2, setPassword2] = useState('');


    const onSubmit = e => {
        e.preventDefault();
        if (password === password2 && password.length >= 6)
        updatePassword(user.requestKey, password, globalState, globalDispatch);
    }

    return (   
        <div className = "pop-login-box">
            <div className = "pop-login-content-box">
                <div className = "pop-login-card-box" >
                    <div className = "pop-login-card-content">
                        <div className = "pop-login-card-header">
                            <PopSvg svg="locked"/>
                            <h3>{popCaption(config, "change-pass-edit").captionLong}</h3>      
                        </div>
                        <form className = "pop-login-fields" onSubmit={onSubmit}>
                            <div className = "pop-login-field">
                                <div className = "pop-login-field-label">
                                    <label htmlFor = "login" >{popCaption(config, "email").captionLong}</label>
                                </div>
                                <div className = "pop-login-field-value">
                                    <p>{user.requestLogin}</p> 
                                </div>                        
                            </div>
                            <div className = "pop-login-field">
                                <div className = "pop-login-field-label">
                                    <label htmlFor = "password" >{popCaption(config, "password").captionLong}</label>
                                </div>
                                <div className = "pop-login-field-value">
                                    <input id = "password" type = "password" name = "password" value = { password } onChange = { o => setPassword(o.target.value) }/>   
                                </div>                        
                            </div> 
                            <div className = "pop-login-field">
                                <div className = "pop-login-field-label">
                                    <label htmlFor = "password" >{popCaption(config, "password2").captionLong}</label>
                                </div>
                                <div className = "pop-login-field-value">
                                    <input id = "password2" type = "password" name = "password2" value = { password2 } onChange = { o => setPassword2(o.target.value) }/> 
                                    <button className="pop-login-field-icon">
                                        <PopSvg svg="return"/>
                                    </button>      
                                </div>                        
                            </div> 
                        </form>
                    </div>
                </div>
            </div>
        </div> 
    );
}

function PopUpdateDelete() {
    const { globalState, globalDispatch } = useContext(Context);
    const { config, user } = globalState;
    const [password, setPassword] = useState('');


    const onSubmit = e => {
        e.preventDefault();
        if (password.length >= 6)
        updateDelete(user.requestKey, password, globalState, globalDispatch);
    }

    return (
        <div className = "pop-login-box">
            <div className = "pop-login-content-box">
                <div className = "pop-login-card-box" >
                    <div className = "pop-login-card-content">
                        <div className = "pop-login-card-header">
                            <PopSvg svg="info"/>
                            <h3>{popCaption(config, "please-delete").captionLong}</h3>      
                        </div>
                        <form className = "pop-login-fields" onSubmit={onSubmit}>
                            <div className = "pop-login-field">
                                <div className = "pop-login-field-label">
                                    <label htmlFor = "login" >{popCaption(config, "email").captionLong}</label>
                                </div>
                                <div className = "pop-login-field-value">
                                    <p>{user.requestLogin}</p> 
                                </div>                        
                            </div>
                            <div className = "pop-login-field">
                                <div className = "pop-login-field-label">
                                    <label htmlFor = "password" >{popCaption(config, "password").captionLong}</label>
                                </div>
                                <div className = "pop-login-field-value">
                                    <input id = "password" type = "password" name = "password" value = { password } autoFocus onChange = { o => setPassword(o.target.value) }/>  
                                    <button className="pop-login-field-icon">
                                        <PopSvg svg="return"/>
                                    </button> 
                                </div>                        
                            </div> 
                        </form>
                    </div>
                </div>
            </div>
        </div> 
    );
}

function PopRegister() {
    const { globalState, globalDispatch } = useContext(Context);
    const { config, user } = globalState;
    const [password, setPassword] = useState('');
    const [password2, setPassword2] = useState('');


    const onSubmit = e => {
        e.preventDefault();
        if (password === password2 && password.length >= 6)
        updateRegister(user.requestLogin, password, null, globalState, globalDispatch);
    }

    return (
        <div className = "pop-login-box">
            <div className = "pop-login-content-box">
                <div className = "pop-login-card-box" >
                    <div className = "pop-login-card-content">
                        <div className = "pop-login-card-header">
                            <PopSvg svg="person"/>
                            <h3>{popCaption(config, "please-register").captionLong}</h3>      
                        </div>
                        <form className = "pop-login-fields" onSubmit={onSubmit}>
                            <div className = "pop-login-field">
                                <div className = "pop-login-field-label">
                                    <label htmlFor = "login" >{popCaption(config, "email").captionLong}</label>
                                </div>
                                <div className = "pop-login-field-value">
                                    <p>{user.requestLogin}</p> 
                                </div>                        
                            </div>
                            <div className = "pop-login-field">
                                <div className = "pop-login-field-label">
                                    <label htmlFor = "password" >{popCaption(config, "password").captionLong}</label>
                                </div>
                                <div className = "pop-login-field-value">
                                    <input id = "password" type = "password" name = "password" value = { password } autoFocus onChange = { o => setPassword(o.target.value) }/>  
                                </div>                        
                            </div> 
                            <div className = "pop-login-field">
                                <div className = "pop-login-field-label">
                                    <label htmlFor = "password" >{popCaption(config, "password2").captionLong}</label>
                                </div>
                                <div className = "pop-login-field-value">
                                    <input id = "password2" type = "password" name = "password2" value = { password2 } onChange = { o => setPassword2(o.target.value) }/> 
                                    <button className="pop-login-field-icon">
                                        <PopSvg svg="return"/>
                                    </button>      
                                </div>                        
                            </div> 
                        </form>
                    </div>
                </div>
            </div>
        </div> 
    );
}

function PopJoin() {
    const { globalState, globalDispatch } = useContext(Context);
    const { config, user, navigation } = globalState;
    const [login, setLogin] = useState('');
    const [vCode, setVCode] = useState('');
    const [password, setPassword] = useState('');
    const [fullName, setFullName] = useState('');
    const [password2, setPassword2] = useState('');
   
    const onClickEdit = e => {
        e.preventDefault();
        user.emailValidated = false;
        user.newEmail = "";
        setLogin("");
        globalDispatch( { type: 'update-state', state: globalState });   
    }

    const onSubmit = e => {
        e.preventDefault();
        if (!user.emailValidated) {
            setLogin(normalizeEmail(login));
            if (validEmail(login)) checkNewEmail(navigation.langId, login, globalState, globalDispatch);
        } else if (password === password2 && password.length >= 6 && fullName.length > 0) updateJoin(login, fullName, password, vCode, globalState, globalDispatch);
    }
    return (
        <div className = "pop-login-box">
            <div className = "pop-login-content-box">
                <div className = "pop-login-card-box" >
                    <div className = "pop-login-card-content">
                        <div className = "pop-login-card-header">
                            <PopSvg svg="person"/>
                            <h3>{popCaption(config, "please-register").captionLong}</h3>      
                        </div>
                        <form className = "pop-login-fields" onSubmit={onSubmit}>
                            { !user.emailValidated ?
                            <Fragment>
                            <div className = "pop-login-field">
                                <div className = "pop-login-field-label">
                                    <label htmlFor = "login" >{popCaption(config, "email").captionLong}</label>
                                </div>
                                <div className = "pop-login-field-value">
                                    <input id = "login" type = "text" autoFocus name = "login" value = { login } onChange = { o => { setLogin(o.target.value); }}/> 
                                    <button className="pop-login-field-icon">
                                        <PopSvg svg="return"/>
                                    </button>                                          
                                </div>                  
                            </div>
                            { (user.requestError) && (user.newEmail === login) &&
                            <div className = "pop-login-field">
                                <div className = "pop-login-field-label">
                                    <PopSvg svg="error"/>
                                </div>
                                <div className = "pop-login-field-value">
                                    <p>{popCaption(config, user.requestError).captionLong}</p> 
                                </div>
                            </div> }                           
                            </Fragment> :
                            <Fragment>
                            <div className = "pop-login-field">
                                <div className = "pop-login-field-label">
                                    <label htmlFor = "login" >{popCaption(config, "email").captionLong}</label>
                                </div>
                                <div className = "pop-login-field-value">
                                    <p>{login}</p> 
                                    <button className="pop-login-field-icon">
                                        <PopSvg svg="edit" onClick={onClickEdit}/>
                                    </button>                                       
                                </div>                        
                            </div>
                            <div className = "pop-login-field">
                                <div className = "pop-login-field-label">
                                    <label htmlFor = "fullName" >{popCaption(config, "fullName").captionLong}</label>
                                </div>
                                <div className = "pop-login-field-value">
                                    <input id = "fullName" type = "text" name = "fullName" value = { fullName } onChange = { o => setFullName(o.target.value) }/>  
                                </div>                        
                            </div> 
                            <div className = "pop-login-field">
                                <div className = "pop-login-field-label">
                                    <label htmlFor = "password" >{popCaption(config, "password").captionLong}</label>
                                </div>
                                <div className = "pop-login-field-value">
                                    <input id = "password" type = "password" name = "password" value = { password } onChange = { o => setPassword(o.target.value) }/>  
                                </div>                        
                            </div> 
                            <div className = "pop-login-field">
                                <div className = "pop-login-field-label">
                                    <label htmlFor = "password" >{popCaption(config, "password2").captionLong}</label>
                                </div>
                                <div className = "pop-login-field-value">
                                    <input id = "password2" type = "password" name = "password2" value = { password2 } onChange = { o => setPassword2(o.target.value) }/>                                    
                                </div>                        
                            </div> 
                            <div className = "pop-login-field">
                                <div className = "pop-login-field-label">
                                    <label htmlFor = "vcode" >{popCaption(config, "code").captionLong}</label>
                                </div>
                                <div className = "pop-login-field-value">
                                    <input id = "vcode" type = "code" name = "code" value = { vCode } onChange = { o => setVCode(o.target.value) }/> 
                                    <button className="pop-login-field-icon">
                                        <PopSvg svg="return"/>
                                    </button>                                          
                                </div>                        
                            </div>                            
                            </Fragment> }                          
                        </form>
                    </div>
                </div>
            </div>
        </div> 
    );
}



function PopAccess() {
    const { globalState, globalDispatch } = useContext(Context);
    const { config, user } = globalState;
    const [password, setPassword] = useState('');
    const [password2, setPassword2] = useState('');


    const onSubmit = e => {
        e.preventDefault();
        if (password === password2 && password.length >= 6)
        updateAccess(password, globalState, globalDispatch);
    }

    return (
        <div className = "pop-login-box">
            <div className = "pop-login-content-box">
                <div className = "pop-login-card-box" >
                    <div className = "pop-login-card-content">
                        <div className = "pop-login-card-header">
                            <PopSvg svg="person"/>
                            <h3>{popCaption(config, "please-register").captionLong}</h3>      
                        </div>
                        <form className = "pop-login-fields" onSubmit={onSubmit}>
                            <div className = "pop-login-field">
                                <div className = "pop-login-field-label">
                                    <label htmlFor = "login" >{popCaption(config, "email").captionLong}</label>
                                </div>
                                <div className = "pop-login-field-value">
                                    <p>{user.requestLogin}</p> 
                                </div>                        
                            </div>
                            <div className = "pop-login-field">
                                <div className = "pop-login-field-label">
                                    <label htmlFor = "password" >{popCaption(config, "password").captionLong}</label>
                                </div>
                                <div className = "pop-login-field-value">
                                    <input id = "password" type = "password" name = "password" value = { password } autoFocus onChange = { o => setPassword(o.target.value) }/>  
                                </div>                        
                            </div> 
                            <div className = "pop-login-field">
                                <div className = "pop-login-field-label">
                                    <label htmlFor = "password" >{popCaption(config, "password2").captionLong}</label>
                                </div>
                                <div className = "pop-login-field-value">
                                    <input id = "password2" type = "password" name = "password2" value = { password2 } onChange = { o => setPassword2(o.target.value) }/> 
                                    <button className="pop-login-field-icon">
                                        <PopSvg svg="return"/>
                                    </button>      
                                </div>                        
                            </div> 
                        </form>
                    </div>
                </div>
            </div>
        </div> 
    );
}

function PopEmail() {
    const { globalState } = useContext(Context);
    const { user } = globalState;
    return (
       user.emailChanged ? 
        <PopMessage msg="change-email-thanks"/> : 
        <PopEditEmail/>
    )
}

function PopEditEmail() {
    const { globalState, globalDispatch } = useContext(Context);
    const { config, user, navigation } = globalState;
    const [vCode, setVcode] = useState("");
    const [newEmail, setNewEmail] = useState('');
    

    const onSubmit = e => {
        e.preventDefault();
        setNewEmail(normalizeEmail(newEmail));
        if (newEmail !== user.newEmail && newEmail !== user.requestLogin && validEmail(newEmail)) checkNewEmail(navigation.langId, newEmail, globalState, globalDispatch);
        else if (user.emailValidated) updateEmail(user.requestKey, vCode, globalState, globalDispatch);
    }

    return (   
        <div className = "pop-login-box">
            <div className = "pop-login-content-box">
                <div className = "pop-login-card-box" >
                    <div className = "pop-login-card-content">
                        <div className = "pop-login-card-header">
                            <PopSvg svg="info"/>
                            <h3>{popCaption(config, "please-change-email").captionLong}</h3>      
                        </div>
                        <form className = "pop-login-fields" onSubmit={onSubmit}>
                            <div className = "pop-login-field">
                                <div className = "pop-login-field-label">
                                    <label htmlFor = "email" >{popCaption(config, "email").captionLong}</label>
                                </div>
                                <div className = "pop-login-field-value">
                                    <p>{user.requestLogin}</p> 
                                </div>                        
                            </div>

                            <div className = "pop-login-field">
                                <div className = "pop-login-field-label">
                                    <label htmlFor = "vcode" >{popCaption(config, "newEmail").captionLong}</label>
                                </div>
                                <div className = "pop-login-field-value">
                                    <input id = "newEmail" name = "newEmail" value = { newEmail } type="email" onChange = { o => setNewEmail(o.target.value) }/> 
                                    { (!user.emailValidated || (newEmail !== user.newEmail)) && <button className="pop-login-field-icon">
                                        <PopSvg svg="return"/>
                                    </button>}
                                </div>                        
                            </div> 
                           { !user.emailValidated && (newEmail === user.newEmail) && (user.requestError) &&
                           <div className = "pop-login-field">
                                <div className = "pop-login-field-label">
                                    <PopSvg svg="error"/>
                                </div>
                                <div className = "pop-login-field-value">
                                    <p>{popCaption(config, user.requestError).captionLong}</p> 
                                </div>
                           </div> }
                           { user.emailValidated && (newEmail === user.newEmail) && 
                           <div className = "pop-login-field">
                                <div className = "pop-login-field-label">
                                    <label htmlFor = "vcode" >{popCaption(config, "code").captionLong}</label>
                                </div>
                                <div className = "pop-login-field-value">
                                    <input id = "vcode" name = "vcode" value = { vCode } type="text" pattern="\d*" maxlength="6" onChange = { o => setVcode(o.target.value) }/> 
                                    <button className="pop-login-field-icon">
                                        <PopSvg svg="return"/>
                                    </button>      
                                </div>                        
                            </div> }
                        </form>                            
                    </div>
                </div>
            </div>
        </div> 
    );
}



export { PopMessage, PopPassword, PopDelete, PopRegister, PopJoin, PopAccess, PopEmail } ;