import React, { useContext, useState} from 'react';
import Context from './Context';
import populae from './populae.svg'
import { PopSvg, popCaption } from './PopSvg'
import { searchObjMultiple } from './PopApiGlobal';

function PopHeader() {
    const { globalDispatch, globalState } = useContext(Context);
    const [ searchValue, setSearchValue ] = useState('');
    const { user, navigation, config } = globalState;
    
    
    const objTypes = () => config.objTypes.filter(objType => objType.objTypeUse !== 0);

    return (
        <div className = "pop-header-box">
            <div className="pop-header-title">
                <PopSvg svg="menu" onClick = {() => { navigation.showMenu = !navigation.showMenu; navigation.showFolders = false; globalDispatch( { type: 'update-state', state: globalState }); } }/>
                <img src={populae} alt="icon"></img>
            </div>
            { user.userLogged && 
            <form className="pop-header-search">
                <button type = "submit" onClick = {(o => { o.preventDefault(); if (searchValue !== '') searchObjMultiple(objTypes()[navigation.searchObjType].objTypeId, config.objTypes[navigation.searchObjType].attIdName,  searchValue, globalState, globalDispatch); setSearchValue('') })}>
                    <PopSvg svg = "search"/>
                </button>
                <input id="searchObjects" type="text" placeholder={popCaption(config, "search").captionLong} value ={searchValue} tabIndex = {0} onChange = {(o => setSearchValue(o.target.value))}/>
                <PopSvg svg={objTypes()[navigation.searchObjType].objTypeSvg} onClick={()=> {
                    navigation.searchObjType = (navigation.searchObjType + 1) % objTypes().length; globalDispatch( { type: 'update-state', state: globalState }); }}/>              
            </form> }
        </div> );
}


export default PopHeader;

