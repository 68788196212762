import { pop, FILE_LOCATION } from './PopConst';
import { isNull,  fileIsUrl, execFunction, objPoi, filterObject, normalizeObjAttValue, nowToymd } from './PopFunction'


    function structAttributes(attributes) {
        attributes.forEach(att => { att.parentAtt = null; att.childAtt = []; att.equalAtt = null; } );
        attributes.filter(att => att.attHasChilds > 0).forEach(att => {
            attributes.filter(chiAtt => chiAtt.objTypeId === att.objTypeId && chiAtt.attParent === att.attId).forEach(chiAtt => { 
                att.childAtt.push(chiAtt); 
                chiAtt.parentAtt = att;              
            })
            
        });
        attributes.filter(att => att.attEqual !== null && att.objTypeEqual !== null).forEach(att => {
            let findAtt = attributes.find(attEq => attEq.objTypeId === att.objTypeEqual && attEq.attId === att.attEqual);
            if (findAtt !== undefined) att.equalAtt = findAtt;
        })
        attributes.filter(att => att.attHasChilds > 0).forEach(att => {
            let childAtt = att.childAtt.filter(att => att.attTable != null);
            if (childAtt.length >= 1) {
                att.isTable = true;
                att.tableAtt = [].concat(childAtt);
                if (att.attTable != null) att.tableAtt.push({...att});
                att.tableAtt.sort((a,b) => a.attTable - b.attTable);
                let attTableAcum = att.tableAtt.reduce((acum, att) => { if (acum.attWidth + att.attWidth < 300) { return ({ attWidth: acum.attWidth + att.attWidth, attCount: acum.attCount + 1 })} else  return (acum)}, {attWidth: 0, attCount: 0})
                att.attTableCount = attTableAcum.attCount;
                att.tableAtt.slice(0,att.attTableCount).forEach(att => att.flexBasis = Math.round(100 * att.attWidth / (attTableAcum.attWidth)));
            }
        })
    }

    function valueObjChildChanged(objAttVal, value) {
        if ((objAttVal.val.objChild === value.valueId) || (isNull(value.valueId) && (objAttVal.att.attFunction === null))) return false;
        objAttVal.val.attValue = value.value;
        objAttVal.val.objChild = value.valueId; 
        objAttVal.val.objChildTypeId = objAttVal.att.objChildTypeId;     
        objAttVal.val.nullValue = false;
        objAttVal.val.attVerified = null;
        return true;
    }

    function valueIdChanged(objAttVal, value) {
        if (objAttVal.att.objChildTypeId) return valueObjChildChanged(objAttVal, value);
        if ((objAttVal.val.attValueId === value.valueId) || (isNull(value.valueId) && (objAttVal.att.attFunction === null))) return false;
        objAttVal.val.attValue = value.value;
        objAttVal.val.attValueId = value.valueId; 
        if (value.valueId) objAttVal.val.attValueName = value.value;
        if (!isNull(objAttVal.val.attValueId)) {
            let findVal = objAttVal.att.attValues.find(val => val.valueId === objAttVal.val.attValueId);
            if (findVal !== undefined) { objAttVal.val.attValueSvg = findVal.valueSvg;  objAttVal.val.attValueUrl = findVal.valueUrl; }
        }
        objAttVal.val.nullValue = false;
        objAttVal.val.attVerified = null;
        return true;
    }


    function valueChanged(objAttVal, value) {  
        if (objAttVal.att.htmlType === "select") return valueIdChanged(objAttVal, value);
        normalizeObjAttValue(objAttVal.att.htmlType, value);
        if (objAttVal.att.htmlType === "link") {
            if (!valueIdChanged(objAttVal, value)  && (objAttVal.val.attValue === value.value || isNull(value.value)) ) return false;
            objAttVal.val.attValue = value.value;
            objAttVal.val.nullValue = isNull(value.value);
            objAttVal.val.attVerified = null;
            return true;
        }
        if ((objAttVal.val.attValue === value.value) || (isNull(value.value) && (objAttVal.att.attFunction === null))) return false;
        objAttVal.val.attValue = value.value;
        objAttVal.val.nullValue = isNull(value.value);
        objAttVal.val.attVerified = null;
        return true;
    }


    function cloneObjAttVal(objAttVal) {
        let clonnedObjAttVal = {...objAttVal, nav: {...objAttVal.nav, clonned: true} };
        clonnedObjAttVal.childAtt = [];
        
        objAttVal.childAtt.forEach(att => {
            let clonnedChild = cloneObjAttVal(att)
            clonnedObjAttVal.childAtt.push(clonnedChild);
            clonnedChild.parentAtt = clonnedObjAttVal;
        }) 
        return clonnedObjAttVal;
    }


    function attToVal(att) {
        return ({ objTypeId: att.objTypeId, 
                 attId: att.attId,  
                 attParent: att.attParent, 
                 attOrder: att.attOrder, 
                 attAccess: att.attAccess, 
                 attRelated: att.attRelated, 
                 cardId: att.cardId
                })
    }

    function assignChilds(parentAtt) {
        if (parentAtt.att.attId === 91) {
            console.log("este si");
        }
        parentAtt.att.childAtt.forEach(attChild => {
            let findAtt = parentAtt.obj.objAttValues.filter(attVal => ((attVal.att === attChild) && (attVal.val.attParentInst === parentAtt.val.attInst) && (attVal.parentAtt === null || attVal.parentAtt === parentAtt) ));
            if (findAtt[0] === undefined) {
                let objAttValue = { obj: parentAtt.obj, att: attChild, val: { ...attToVal(attChild), attInst: 0, attInstOrd: 1, attParentInst: parentAtt.val.attInst, objChild: null, objChildTypeId: null, nullValue: true, attActive: false, lastUpdate: null }, nav: { newInst : true }, parentAtt: parentAtt, childAtt: [] };
                parentAtt.obj.objAttValues.push(objAttValue);
                parentAtt.childAtt.push(objAttValue);
                if (objAttValue.att.attHasChilds > 0) assignChilds(objAttValue);
            }
            else { 
                findAtt.forEach(objVal => {
                    parentAtt.childAtt.push(objVal);
                    objVal.parentAtt = parentAtt;
                    if (objVal.att.attHasChilds > 0) assignChilds(objVal);
                });
            }
        });
    }


function listAttCount(listAtt, objAttValue) {
    if (listAtt.length === 0) listAtt.push({ obj: objAttValue.obj, parentAtt: objAttValue.parentAtt, att: objAttValue.att, val: { attParentInst: objAttValue.val.attParentInst }, attCount: 1 });
    else {
        let findAtt = listAtt.find(i => i.obj === objAttValue.obj && i.att === objAttValue.att && i.parentAtt === objAttValue.parentAtt);
        if (findAtt === undefined) listAtt.push({ obj: objAttValue.obj, parentAtt: objAttValue.parentAtt, att: objAttValue.att, val: { attParentInst: objAttValue.val.attParentInst }, attCount: 1 });
        else findAtt.attCount = findAtt.attCount + 1;
    }
    return listAtt; 
}

function updateListParent(objAttValue) {  
    if (!objAttValue.nav.isHeader) return;
    let selectedAtt = objAttValue.childAtt.find(att => att.val.attActive);
    if (selectedAtt !== undefined) { objAttValue.val = { ...selectedAtt.val, attInst: 0 }; objAttValue.selAtt = selectedAtt; }
    else {
        objAttValue.selAtt = null;
        objAttValue.val.attValue = objAttValue.childAtt.length;
        objAttValue.val.attValueId = null;
        objAttValue.val.attValueSvg = null;
        objAttValue.val.attValueUrl = null;
        objAttValue.val.objChild = null;
        objAttValue.val.attActive = false;
     };
}

function addlistParent(attList) {

     let childAtt = attList.obj.objAttValues.filter(objVal => objVal.att === attList.att && objVal.parentAtt === attList.parentAtt && objVal.val.attParentInst === attList.val.attParentInst ); 
     let selectedAtt = childAtt.find(att => att.val.attActive);
    
     let isTable = (childAtt[0].att.attHasChilds > 0) && (childAtt[0].att.childAtt.filter(att => att.attTable != null).length > 0);
     let objAttValue = { obj: attList.obj, att: childAtt[0].att, nav: { isHeader: true, isTable: isTable, showChilds: true, attTableSel: 1 }, parentAtt: childAtt[0].parentAtt, childAtt: childAtt };
     if (selectedAtt !== undefined) { objAttValue.val = {...selectedAtt.val, attInst: 0 }; objAttValue.selAtt = selectedAtt; objAttValue.nav.showChilds = false; }
     else {
        objAttValue.val = { attInst: 0, attValue: attList.attCount, attValueId: null, attValueSvg: null, attValueUrl: null, objChild: null, attActive: false, cardId: childAtt[0].val.cardId, attOrder: childAtt[0].val.attOrder };
        objAttValue.selAtt = null;
     }
     if (objAttValue.parentAtt !== null) {
        childAtt.forEach(att => {
            let indexAtt = objAttValue.parentAtt.childAtt.indexOf(att);
            if (indexAtt > -1) objAttValue.parentAtt.childAtt.splice(indexAtt, 1);
        })
        objAttValue.parentAtt.childAtt.push(objAttValue); 
     }

     childAtt.forEach(att => { att.parentAtt = objAttValue; att.nav.isItem = true; });
     attList.obj.objAttValues.push(objAttValue);
}


function checkReadAccess(attAccess, objId, userAdmin, userId, userIsAdmin) {
    if (userIsAdmin) return true;
    if (objId === userId) return true;
    if (userAdmin.find(objI => objI === userId) !== undefined) return true;
    if (attAccess !== pop.attAccess.private) return true;
    return false;
}

function isObjEditable(object, userId, userIsAdmin) {
    if (userIsAdmin) return true;
    if (object.userIsAdmin) return true;
    if (object.objId === userId) return true;
    if (object.userAdmin.find(objI => objI === userId) !== undefined) return true;
    if (object.objRelated === 0) return true; 
    return false;
}

function isAttEditable(objattVal) {
    const {nav, att, val} = objattVal;

    return ((att.attFunction === null || (att.attFunction !== null && att.attFunction.optional)) && !nav.isHeader && !nav.objLink && !val.attVerified)
}

function filterObjCardAttVal(objCard) {
    return objCard.object.objAttValues.filter(objAttVal => objAttVal.val.cardId === objCard.card.cardId && objAttVal.parentAtt === null ).sort((a,b)=>{return (a.val.cardId - b.val.cardId || a.val.attOrder - b.val.attOrder) });
}

function structObjectCards(object, cards, userId, userIsAdmin, showNull)
{
    object.objCards = [];
    cards.filter(card => card.objTypeId === object.objTypeId).forEach(card => {    
        if (checkReadAccess(card.cardAccess, object.objId, object.userAdmin, userId, userIsAdmin)) 
        object.objCards.push({ card: card, object: object }); 
    });
    object.objCards.forEach(objCard => 
        { 
            objCard.selMode = false;
            let objAttValues = filterObjCardAttVal(objCard);
            objCard.hasChilds = (objAttValues.length > 0);       
            objCard.attCount = objAttValues.filter(attVal => !attVal.val.nullValue).length;     
            objCard.attMandatory = objAttValues.filter(attVal => (attVal.att.attUse === pop.attUse.mandatory)).length;     
            objCard.attCountAll = objAttValues.length;     
            objCard.showDetail = (objCard.attMandatory > 0) || ((objCard.attCount > 0) && (objCard.card.cardUse !== pop.cardUse.hidden));
            objCard.showNull = showNull;
        })
        object.objCards.filter(objCard => objCard.card.cardParent !== 0).forEach(objCard => 
        { 
            let parentCard = object.objCards.find(parentCard => parentCard.card.cardId === objCard.card.cardParent); 
            if (parentCard !== undefined) { 
                if (parentCard.childCards === undefined) parentCard.childCards = []; 
                parentCard.childCards.push(objCard); objCard.parentCard = parentCard; 
            }
        });    
}


function structObjProfile(object) {
    let objAttVal = object.objAttValues.find(objAtt => objAtt.att.attField === "objProfile" && !objAtt.val.nullValue && objAtt.val.attInstOrd === 1);
    if (objAttVal !== undefined) object.objProfile = objAttVal.val.attShortFile;
    else object.objProfile = null;  
    if (object.objType.objTypeId === pop.objType.person) {
        objAttVal = object.objAttValues.find(objAtt => objAtt.att.attField === "objDeath" && !objAtt.val.nullValue && objAtt.val.attInstOrd === 1);
        if (objAttVal !== undefined) object.objDeath = objAttVal.val.attValue;
        objAttVal = object.objAttValues.find(objAtt => objAtt.att.attField === "objHistorical" && !objAtt.val.nullValue && objAtt.val.attInstOrd === 1);
        if (objAttVal !== undefined) object.objHistorical = objAttVal.val.attValueId;
        objAttVal = object.objAttValues.find(objAtt => objAtt.att.attField === "objAuth" && !objAtt.val.nullValue && objAtt.val.attInstOrd === 1);
        if (objAttVal !== undefined) object.objAuth = objAttVal.val.attValueId;
        objAttVal = object.objAttValues.find(objAtt => objAtt.att.attField === "objEmail" && !objAtt.val.nullValue && objAtt.val.attInstOrd === 1);
        if (objAttVal !== undefined) object.objEmail = objAttVal.val.attValue;
    }
}


/*
    object.objAttValues.filter(objAtt => objAtt.att.attField && !objAtt.val.nullValue && objAtt.val.attInstOrd === 1).forEach(objAtt => {
        object[objAtt.att.attField] = convertObjAttValue(objAtt);
    })

*/

function structObjectHeader(object) {

    let objAttVal = object.objAttValues.find(objAtt => objAtt.att.attField === "objName" && !objAtt.val.nullValue  && objAtt.val.attInstOrd === 1);
    if (objAttVal !== undefined) object.objName = objAttVal.val.attValue;
    else object.objName = object.objType.objTypeName;    

    structObjProfile(object);

    object.objSvg = object.objType.objTypeSvg;

    object.userAdmin = [];    
    object.objAttValues.filter(objAtt => objAtt.att.attField === "objAdmin" && !objAtt.val.nullValue).forEach(objAtt => object.userAdmin.push(objAtt.val.objChild)); 

    objAttVal = object.objAttValues.find(objAtt => objAtt.att.attField === "objLat" && !objAtt.val.nullValue && objAtt.val.attInstOrd === 1);
    if (objAttVal !== undefined) object.objLat = parseFloat(objAttVal.val.attValue);
    else object.objLat = null;

    objAttVal = object.objAttValues.find(objAtt => objAtt.att.attField === "objLng" && !objAtt.val.nullValue && objAtt.val.attInstOrd === 1);
    if (objAttVal !== undefined) object.objLng = parseFloat(objAttVal.val.attValue);
    else object.objLng = null;

}


function structObjAttTables(object) {
    object.objAttValues.filter(objAttVal => objAttVal.nav.isTable && objAttVal.nav.isHeader).forEach(objAttVal => {
        objAttVal.nav.column0 = 1;              
    })
}

function updateObjectDependentValues(objAttVal) {
    if (objAttVal.att.attFunction !== null && objAttVal.val.nullValue) execFunction(objAttVal, false);
    objAttVal.obj.objAttValues.filter(objAtt => objAtt.att.attFunction !== null && 
                                                objAtt.att.attFunction.atts.find(chi => chi === objAttVal.att.attId) !== undefined &&
                                                (objAtt.parentAtt === objAttVal.parentAtt || objAtt === objAttVal.parentAtt)).forEach(objAtt => {
        let lastValue = objAtt.val.attValue;
        execFunction(objAtt, false);
        objAtt.val.changed = (lastValue !== objAtt.val.attValue);
    })

}

function updateObjectValueName(objAttVal) {
    let value = objAttVal.att.attValues.find(val => val.valueId === objAttVal.val.attValueId);
    if (value !== undefined) {
        objAttVal.val.attValueSvg = value.valueSvg;
        objAttVal.val.attValueUrl = value.valueUrl;
        objAttVal.val.attValueShort = value.valueShort;
        objAttVal.val.attValueName = value.valueName;
    }
}




function updateObjectValueNames(object) {
    object.objAttValues.filter(objAttVal => objAttVal.val.attValueId && objAttVal.att.attValues).forEach(objAttVal => { 
        updateObjectValueName(objAttVal);
    })    
}


function updateObjectFunctionValues(object) {
    object.objAttValues.filter(objAttVal => objAttVal.att.attFunction !== null).forEach(objAttVal => execFunction(objAttVal, true));
}

function updateAttFileValue(objAttVal) {
    let values = [];
    if (objAttVal.val.attValue.indexOf(",") >= 0) values = objAttVal.val.attValue.split(',');
    objAttVal.val.attOriginalFile = (fileIsUrl(values[1]) ? '' : FILE_LOCATION) + values[1];
    objAttVal.val.attShortFile = values[0];
    let now = new Date();
    if (objAttVal.val.lastUpdate === null) objAttVal.val.lastUpdate = now.toISOString();
}


function updateObjectFileValue(object) {
    object.objAttValues.filter(objVal => objVal.att.htmlType === "file" && !objVal.val.nullValue).forEach(objVal => {
        updateAttFileValue(objVal);
    })
}

function updateObjectNullAttributes(object, attributes) {
    attributes.filter(att => att.attFunction !== null).forEach(att => {
        let objAttValue = object.objAttValues.find(attVal => attVal.att === att);
        if (objAttValue === undefined) {
            objAttValue = { obj: object, att: att, val: { ...attToVal(att), attInst: 0, attInstOrd: 1, attParentInst: 0, objChild: null, objChildTypeId: null, nullValue: true, attActive: false, lastUpdate: null }, nav: { newInst : true }, parentAtt: null, childAtt: [] };
            object.objAttValues.push(objAttValue);
        }
     })
}

function structObject(object, globalState) {
    let attribute = {};
    let objAttValue = {};
    let attributes = [];
    
    object.objAttValues = [];
    object.objType = globalState.config.objTypes.find(o => o.objTypeId === object.objTypeId);   
    attributes = globalState.config.attributes.filter(att => att.objTypeId === object.objTypeId);
    object.attributes = attributes;
    
    object.objValues.forEach(val => {     
        attribute = attributes.find(att => att.attId === val.attId);
        if (attribute !== undefined) {
            objAttValue = { obj: object, att: attribute, val: val, nav: {}, parentAtt: null, childAtt: [] };
            object.objAttValues.push(objAttValue);
        }
    });

    attributes.filter(att => att.parentAtt === null).forEach(att => {
        objAttValue = object.objAttValues.find(attVal => attVal.att === att);
        if (objAttValue === undefined) {
            objAttValue = { obj: object, att: att, val: { ...attToVal(att), attInst: 0, attInstOrd: 1, attParentInst: 0, objChild: null, objChildTypeId: null, nullValue: true, attActive: false, lastUpdate: null }, nav: { newInst : true }, parentAtt: null, childAtt: [] };
            object.objAttValues.push(objAttValue);
        }
    })

 
    object.objAttValues.filter(attVal => ((attVal.att.attHasChilds > 0) && (attVal.att.parentAtt === null))).forEach(attVal => assignChilds(attVal)); 
    updateObjectFileValue(object);
    updateObjectValueNames(object);
    updateObjectFunctionValues(object);
    structObjectHeader(object);
    
    let listAtt = object.objAttValues.reduce(listAttCount, []).filter(att => att.attCount > 1); 
    listAtt.forEach(att => addlistParent(att));
    object.editMode = isObjEditable(object, globalState.user.userId, globalState.user.userIsAdmin);
    object.viewHidden = object.editMode && (globalState.user.userId !== object.objId);
    object.objAttValues.filter(attVal => (attVal.parentAtt === null) && (attVal.att.attHasChilds > 0) && !attVal.nav.isHeader && attVal.att.attShowDetail).forEach(attVal => attVal.nav.showChilds = true); 
    structObjectCards(object, globalState.config.cards, globalState.user.userId, globalState.user.userIsAdmin, globalState.navigation.showNull);
    object.objAttValues.sort((a,b)=>{return (a.val.cardId - b.val.cardId || a.val.attOrder - b.val.attOrder) }); 
    object.objAttValues.forEach(objAttVal => objAttVal.childAtt.sort((a,b)=>{return (a.val.cardId - b.val.cardId || a.val.attOrder - b.val.attOrder) }));
    object.objAttValues.filter(objVal => objVal.val.objParentObjId || (objVal.parentAtt && objVal.parentAtt.val.objParentObjId && objVal.att.attEqual)).forEach(objVal => objVal.nav.objLink = true);
    structObjAttTables(object);
}
/*a.attOrder - b.attOrder || a.attParentInst - b.attParentInst || b.attActive - a.attActive || a.attInstOrd - b.attInstOrd;*/

function assignEquals(objType, objAttVal, objValues) {
    if (objAttVal.att.objTypeEqual === objType && objAttVal.att.equalAtt !== null) {
        if (!objAttVal.val.nullValue) objValues.push({ ...attToVal(objAttVal.att.equalAtt), changed: true, objId: 0, attInst: 0, attInstOrd: 1, attParentInst: 0, objChildTypeId: objAttVal.val.objChildTypeId, objChild: objAttVal.val.objChild, objChildProfile: null, attValue: objAttVal.val.attValue, attValueId: objAttVal.val.attValueId, attActive: false, nullValue: false});  
        objAttVal.childAtt.forEach(chiVal => { assignEquals(objType, chiVal, objValues) })
    }
}

function addNewObject(objAttVal, globalState) {
    let objValues = []; 
    let attribute = {};
    let objTypeId = null;
    if (objAttVal) {
        objTypeId = objAttVal.att.objChildTypeId;
        assignEquals(objAttVal.att.objChildTypeId, objAttVal, objValues);   
        if (objAttVal.att.objChildAttId !== null) {
            attribute = globalState.config.attributes.find(att => att.objTypeId === objTypeId && att.attId === objAttVal.att.objChildAttId);
            objValues.push({ ...attToVal(attribute), changed: true, objId: 0, attInst: 0, attInstOrd: 1, attParentInst: 0, objChildTypeId: 1, objChild: objAttVal.obj.objId, objChildProfile: objAttVal.obj.objProfile, attValue: objAttVal.obj.objName, attValueId: null, attActive: false, nullValue: false, attVerified: true});
        }
    } else {
        objTypeId =   globalState.config.objTypes[globalState.navigation.searchObjType].objTypeId;
    }
    attribute = globalState.config.attributes.find(att => att.objTypeId === objTypeId && att.attField === "objAdmin");
    if (attribute) objValues.push({ ...attToVal(attribute), changed: true, objId: 0, attInst: 0, attInstOrd: 1, attParentInst: 0, objChildTypeId: 1, objChild: globalState.user.userId, objChildProfile: globalState.user.userProfile, attValue: globalState.user.userName, attValueId: null, attActive: false, nullValue: false});

    attribute = globalState.config.attributes.find(att => att.objTypeId === objTypeId && att.attField === "objProfileDate");
    if (attribute) objValues.push({ ...attToVal(attribute), changed: true, objId: 0, attInst: 0, attInstOrd: 1, attParentInst: 0, objChildTypeId: 1, attValue: nowToymd(), attValueId: null, attActive: false, nullValue: false});

    attribute = globalState.config.attributes.find(att => att.objTypeId === objTypeId && att.attField === "objInvite");
    if (attribute) { 
        let attValue = attribute.attValues.find(val => val.valueTag === "REL");
        if (attValue) objValues.push({ ...attToVal(attribute), changed: true, objId: 0, attInst: 0, attInstOrd: 1, attParentInst: 0, objChildTypeId: 1, attValueId: attValue.valueId, attValue: null, attActive: false, nullValue: false}); }

    return addObject(objTypeId, 0, 0, true, objValues, globalState.data.objects, globalState.navigation.maxObjects);
}

/* 
return addObject(objType.objTypeId, 0, objAttVal.att.attRelated, true, objValues, globalState.data.objects, globalState.navigation.maxObjects);
*/


function addObject(objTypeId, objId, objRelated, userIsAdmin, objValues, objects, maxObjects) {
    hideObjects(objects);
    let object = objects.find(object => object.objTypeId === objTypeId && object.objId === objId);
    if (object === undefined) {
        object = { objTypeId: objTypeId, objId: objId};
        if (objects.length === 0) objects.push(object);
        else {
            if (objects.length < maxObjects) objects.push(objects[object.lenght-1]);
            for (let i = objects.length-1; i >= 1; i--) objects[i] = objects[i-1];
            objects[0] = object;
        }
    }
     object.objValues = objValues;
     object.objRelated = objRelated;
     object.userIsAdmin = userIsAdmin;
     object.showDetail = true;
     object.showFolders = false;
     object.editMode = false;
     object.viewHidden = false;
     objects.sort((a,b) => { return a.objTypeId - b.objTypeId } );
     return object;
}

function closeObject(object, objects) {
    objects.splice(objects.indexOf(object), 1);
    return objects;
}

function hideObjects(objects) {
    objects.forEach(object => { object.showDetail = false; object.showFolders = false; });
}


function pushObjChilds(objAttVal, objAttValues) {
    objAttValues.push(objAttVal);
    if (objAttVal.att.attHasChilds > 0) objAttVal.childAtt.forEach(attChi => pushObjChilds(attChi,objAttValues ));
}

function updateObjChilds(objAttValues, objValues) {
    let listAttParents = [];
    objValues.forEach(objVal => {
        let objAttValue = objAttValues.find(objAttVal => objAttVal.att.objTypeId === objVal.objTypeId && objAttVal.att.attId === objVal.attId && !objAttVal.nav.isHeader);
        if (objAttValue !== undefined) {
            for(var v in objVal) objAttValue.val[v] = objVal[v];           
            if (objAttValue.parentAtt !== null && objAttValue.parentAtt.nav.isHeader && (listAttParents.indexOf(objAttValue.parentAtt) === -1)) {
                listAttParents.push(objAttValue.parentAtt);
            }
        }        
    })  
    objAttValues.filter(objVal => objVal.att.htmlType === "file" && !objVal.val.nullValue).forEach(objVal => updateAttFileValue(objVal));
    objAttValues.filter(objVal => objVal.val.attValueId).forEach(objVal => { updateObjectValueName(objVal); });
    objAttValues.filter(objVal => objVal.att.attFunction !== null).forEach(objVal => execFunction(objVal, true));
    listAttParents.forEach(parentAtt => updateListParent(parentAtt));  
}

function updateObjectInst(objAttValues, objValues) {
    objAttValues.filter(objAttVal => objAttVal.obj.objId === 0 || objAttVal.val.attInst === 0 || (objAttVal.val.attParentInst === 0 && objAttVal.att.attParent !== 0)).forEach(objAttVal => {
        let objValue = objValues.find(objVal => objVal.objTypeId === objAttVal.att.objTypeId && objVal.attId === objAttVal.att.attId);
        if (objValue !== undefined) {
            objAttVal.obj.objId = objValue.objId;
            objAttVal.val.attInst = objValue.attInst;
            objAttVal.val.attParentInst = objValue.attParentInst;
            objAttVal.val.lastUpdate = objValue.lastUpdate;
            if (objAttVal.parentAtt !== null) 
                objAttVal.parentAtt.childAtt.forEach(chiAtt => chiAtt.val.attParentInst = objValue.attParentInst);
            if (objAttVal.att.attHasChilds > 0) objAttVal.childAtt.forEach(chiAtt => chiAtt.val.attParentInst = objValue.attInst);   
        }
    })
}

function updateObject(object, objValues)
{   
    let listAttParents = [];
    objValues.forEach(objVal => {
        let objAttValue = object.objAttValues.find(objAttVal => objAttVal.att.objTypeId === objVal.objTypeId && objAttVal.att.attId === objVal.attId && objAttVal.val.attInst === objVal.attInst && !objAttVal.nav.isHeader);
        if (objAttValue !== undefined) {
            for(var v in objVal) objAttValue.val[v] = objVal[v];
            if (objAttValue.parentAtt !== null && objAttValue.parentAtt.nav.isHeader && (listAttParents.indexOf(objAttValue.parentAtt) === -1)) {
                listAttParents.push(objAttValue.parentAtt);
            }
        }        
    })  
    updateObjectFunctionValues(object);
    updateObjectValueNames(object);
    listAttParents.forEach(parentAtt => updateListParent(parentAtt));
}


function purgeObjAttValues(objAttValues) {
    objAttValues.filter(objAtt => objAtt.val.delete).forEach(objAtt => nullAttValue(objAtt));
    objAttValues.filter(objAtt => objAtt.val.remove).forEach(objAtt => objAttValues.splice(objAttValues.indexOf(objAtt), 1));
}


function deleteObjAttValue(objAttVal, remove, del, deleteChilds) {
    objAttVal.val.delete = del;
    objAttVal.val.remove = remove;
    if (deleteChilds) objAttVal.childAtt.forEach(chiAtt => deleteObjAttValue(chiAtt, remove, del, deleteChilds));
}

function deleteObjChilds(objAttVal) {
    objAttVal.childAtt.filter(chiAtt => chiAtt.att.attEqual !== null).forEach(chiAtt => { chiAtt.val.delete = true; if (chiAtt.att.attHasChilds) deleteObjChilds(chiAtt) });
}


function nullAttValue(objAttVal) {
    objAttVal.val.attValue = null; 
    objAttVal.val.attValueId = null;
    objAttVal.val.objChild = null; 
    objAttVal.nav.objLink = false; 
    objAttVal.val.objChildProfile = null; 
    objAttVal.val.nullValue = true;
    objAttVal.val.attActive = false;
    return objAttVal;
}


function assignObjAttValue(objAttVal, obj) {
    objAttVal.val.attValue = null;
    objAttVal.val.attValueId = null;
    objAttVal.val.objChild = obj.objId;
    objAttVal.val.objChildTypeId = obj.objTypeId;
    objAttVal.val.objChildProfile = obj.objProfile;
    objAttVal.val.objChildName = obj.objName;
    objAttVal.val.objChildSvg = obj.objSvg;
    objAttVal.val.changed = true;
    objAttVal.val.nullValue = false;
}


function addObjectValueInst(objAttVal) {
    let newObjAttVal = { obj: objAttVal.obj, att: objAttVal.att, val: { ...attToVal(objAttVal.att), attInst: 0, objChild: null, objChildTypeId: null, nullValue: true }, nav: {}, parentAtt: null, childAtt: [] };
    if (objAttVal.nav.isHeader) {
        newObjAttVal.parentAtt = objAttVal;
        newObjAttVal.val.attOrder = objAttVal.childAtt[0].val.attOrder;
        newObjAttVal.val.cardId = objAttVal.childAtt[0].val.cardId;
        newObjAttVal.val.attParentInst = objAttVal.childAtt[0].val.attParentInst;
        newObjAttVal.nav.isItem = true;
        objAttVal.childAtt.push(newObjAttVal);
        newObjAttVal.val.attInstOrd = objAttVal.childAtt.indexOf(newObjAttVal) + 1;
        objAttVal.obj.objAttValues.push(newObjAttVal);
        if (newObjAttVal.att.attHasChilds > 0) assignChilds(newObjAttVal);
        objAttVal.nav.showChilds = true;
        updateListParent(objAttVal);
    }
    if (objAttVal.nav.isItem) {
        newObjAttVal.parentAtt = objAttVal.parentAtt;
        newObjAttVal.val.attOrder = objAttVal.val.attOrder;
        newObjAttVal.val.cardId = objAttVal.val.cardId;
        newObjAttVal.val.attParentInst = objAttVal.val.attParentInst;
        newObjAttVal.nav.isItem = true;
        objAttVal.parentAtt.childAtt.push(newObjAttVal);
        newObjAttVal.val.attInstOrd = objAttVal.parentAtt.childAtt.indexOf(newObjAttVal) + 1;
        objAttVal.obj.objAttValues.push(newObjAttVal);
        if (newObjAttVal.att.attHasChilds > 0) assignChilds(newObjAttVal);
        newObjAttVal.parentAtt.nav.showChilds = true;
        updateListParent(objAttVal.parentAtt);
    }
    if (!objAttVal.nav.isHeader && !objAttVal.nav.isItem) {
        newObjAttVal.parentAtt = objAttVal.parentAtt;
        newObjAttVal.val.attOrder = objAttVal.val.attOrder;
        newObjAttVal.val.cardId = objAttVal.val.cardId;
        newObjAttVal.val.attParentInst = objAttVal.val.attParentInst;
        newObjAttVal.val.attInstOrd = 2;
        objAttVal.obj.objAttValues.push(newObjAttVal);
        if (newObjAttVal.att.attHasChilds > 0) assignChilds(newObjAttVal);
        let listAtt = { obj: objAttVal.obj, parentAtt: objAttVal.parentAtt, att: objAttVal.att, val: { attParentInst: objAttVal.val.attParentInst }, attCount: 1 };
        addlistParent(listAtt);
        newObjAttVal.parentAtt.nav.showChilds = true;
    }
    return newObjAttVal;
}



function deleteObjectValueInst(objAttVal) {
    if (objAttVal.parentAtt === null) {
        deleteObjAttValue(objAttVal, false, true, objAttVal.att.attHasChilds);
        return objAttVal;
    } 
    else {
        if (objAttVal.nav.isItem) {
            let parentAtt = objAttVal.parentAtt; 
            let childAtt = parentAtt.childAtt;
            if (childAtt.length > 2) {
                childAtt.splice(childAtt.indexOf(objAttVal), 1);
                childAtt.forEach((chi,index) => chi.val.attInstOrd = index + 1);
                deleteObjAttValue(objAttVal, true, true, objAttVal.att.attHasChilds);
                updateListParent(parentAtt);
                return parentAtt;
            }
            else {            
                deleteObjAttValue(objAttVal, true, true);
                let objAttValChi = childAtt.find(attVal => attVal.val.attInst !== objAttVal.val.attInst);
                if (parentAtt.parentAtt !== null) {
                    parentAtt.parentAtt.childAtt[parentAtt.parentAtt.childAtt.indexOf(parentAtt)] = objAttValChi;
                }
                objAttValChi.parentAtt = parentAtt.parentAtt;
                objAttValChi.val.attInstOrd = 1;
                objAttValChi.nav.isItem = false;
                deleteObjAttValue(parentAtt, true, false, false);
                return objAttValChi;
            }
        }
        else {
            deleteObjAttValue(objAttVal, false, true, objAttVal.att.attHasChilds);
            return objAttVal;
        }
    }   

}


const checkMaxColum = (acum, column) => {
    if ((column.objTable === 0 || column.objTable >= acum.columnI) && (acum.attWidth + column.attWidth <= 1200)) { return ({ ...acum, attWidth: acum.attWidth + column.attWidth, columnMax: column.objTable }) } return ({...acum});
}

function setHeaderColumns(search) {
    let columnAcum = search.header.reduce(checkMaxColum,{ attWidth: 0, columnI: search.columnI, columnMax: 0});
    search.columnMax = columnAcum.columnMax;
    search.header.filter(column => ((column.objTable === 0) || (column.objTable >= search.columnI && column.objTable <= search.columnMax))).forEach(column => {
        column.flexBasis = Math.round(100 * column.attWidth / columnAcum.attWidth);
    })


}

const findObj = (acum, current, attributes, objType) => { 
    let findObj = acum.find(obj => obj.objId === current.objId && obj.objTypeId === current.objTypeId); 
    if (findObj === undefined) { 
        findObj = { objId: current.objId, objTypeId: current.objTypeId, objType: objType, objAttValues: [] };
        findObj.objAttValues.push({ obj: findObj, parentAtt: null, val: current, att: attributes.find(att => att.attId === current.attId )})
        acum.push(findObj); 
    } 
    else { 
        findObj.objAttValues.push({ obj: findObj, parentAtt: null, val: current, att: attributes.find(att => att.attId === current.attId )}); 
    } 
    return(acum) 
}


function filterSearch(search, searchFields) {
    if (searchFields && searchFields.length > 0)
        search.objects = search.objects.filter(obj => filterObject(obj, searchFields));
}


function structSearch(objTypeId, attId, searchValue, config, result, search) {
    search.objTypeId = objTypeId;
    search.attId      = attId;
    search.objType    = config.objTypes.find(objType => objType.objTypeId === objTypeId);
    let attributesSearch = config.attributes.filter(att => (att.objTypeId === objTypeId) && att.attField);       
    search.objects = result[0].reduce((acum, current) => findObj(acum, current, attributesSearch, search.objType), []);
    search.header = attributesSearch.filter(att => att.objTable != null).sort((a,b) => a.objTable - b.objTable);
    search.objects.forEach(obj => updateObjectNullAttributes(obj, search.header));
    search.objects.forEach(obj => updateObjectFunctionValues(obj));
    search.objects.forEach(obj => updateObjectValueNames(obj));
    search.objects.forEach(obj => updateObjectFileValue(obj));
    search.objects.forEach(obj => structObjectHeader(obj));
    search.column0 = search.header[0].objTable; 
    if (search.column0 === 0) search.column0 = search.header[1].objTable; 
    search.columnI = search.column0;    
    search.columnN = search.header[search.header.length-1].objTable;
    setHeaderColumns(search);
}

function structConnections(config, objvalues, data) {
    data.connections = objvalues;

    const attName = (attId, objTypeId) => { 
        let findAtt = config.attributes.find(att => att.objTypeId === objTypeId && att.attId === attId); 
        if (findAtt !== undefined) { return findAtt.attName } else { return  null }}

    data.connections.forEach(con => {
        con.forAttName = attName(con.forAttId, con.forObjTypeId);
        con.fromAttName = attName(con.fromAttId, con.fromObjTypeId);
    })
}


/*
function structConnections(config, objvalues, connections) {
    let attributes = config.attributes.filter(att => (att.objTypeId === pop.objType.connetion) && att.attField);      
    let objType = config.objTypes.find(objType => objType.objTypeId === pop.objType.connetion);
    connections.objects = objvalues.reduce((acum, current) => findObj(acum, current, attributes, objType), []);
    connections.header = attributes.filter(att => att.objTable != null).sort((a,b) => a.objTable - b.objTable);
    connections.objects.forEach(obj => updateObjectNullAttributes(obj, connections.header));
    connections.objects.forEach(obj => updateObjectFunctionValues(obj));

    let attribute = attributes.find(att => att.attField === "link");
    let attValueId = attribute.attValues.find(att => att.valueSvg === "connect").valueId;
    
    connections.objects.forEach(obj  => { 
        let findVal = obj.objAttValues.find(attVal => attVal.att.attField === "link"); 
        if (findVal !== undefined) findVal.val.attValueId = attValueId; } );
    
     connections.objects.forEach(obj => updateObjectValueNames(obj));

    connections.objects.forEach(obj => obj.objAttValues.filter(attVal => attVal.att.htmlType === "attribute").forEach(attVal => { 
        let findAtt = config.attributes.find(att => att.objTypeId === attVal.val.objChildTypeId && att.attId === attVal.val.attValueId); 
        if (findAtt !== undefined) attVal.val.attValue = findAtt.attName }));



    connections.objects.forEach(obj => updateObjectFileValue(obj));
    connections.objects.forEach(obj => structObjectHeader(obj));
    connections.column0 = connections.header[0].objTable; 
    if (connections.column0 === 0) connections.column0 = connections.header[1].objTable; 
    connections.columnI = connections.column0;    
    connections.columnN = connections.header[connections.header.length-1].objTable;
    setHeaderColumns(connections);    
}
*/


/*
function maxDistancePois(mapPois) {
    let maxDistance = { distanceX: -1, distanceY: -1, lat1: 0, lng1: 0, lat2: 0, lng2: 0 }
    for (let i = 0; i < mapPois.length-1; i++)
        for (let j = i + 1; j < mapPois.length; j++) {
            let newDistanceX = distanceX(parseFloat(mapPois[i].objLng), parseFloat(mapPois[j].objLng), parseFloat(mapPois[i].objLat));
            let newDistanceY = distanceY(parseFloat(mapPois[i].objLat), parseFloat(mapPois[j].objLat));
            if (newDistanceX > maxDistance.distanceX) {
                maxDistance.distanceX = newDistanceX;                
                maxDistance.lng1 = parseFloat(mapPois[i].objLng);
                maxDistance.lng2 = parseFloat(mapPois[j].objLng);
            }
            if (newDistanceY > maxDistance.distanceY) {
                maxDistance.distanceY = newDistanceY;                
                maxDistance.lat1 = parseFloat(mapPois[i].objLat);
                maxDistance.lat2 = parseFloat(mapPois[j].objLat);
            }            
        }
    return maxDistance;        
}
*/

function structMap(navigation, data) {
    data.mapPois = data.search.objects.map(obj => objPoi(obj)).filter(coord => coord.objLat !== null && coord.objLng !== null);
    navigation.showMarkers = false;
    let now = new Date();      
    navigation.mapUpdate = now.toISOString();    
}
/*    
    if (data.mapPois.length > 1) {
      //  let mapCenter = data.mapPois.reduce((a, b) => { return({ lat: a.lat + parseFloat(b.objLat), lng: a.lng + parseFloat(b.objLng) })}, { latMax: -90, lngMax: -180, latMin: 90, lngMin: 180});
      //  let maxDistance = maxDistancePois(data.mapPois);
//        navigation.setMapBounds = { nw: { lat: maxDistance.lat1, lng: maxDistance.lng1 }, se: { lat: maxDistance.lat2, lng: maxDistance.lng2 } };

        navigation.mapCenter.lat = (maxDistance.lat1 + maxDistance.lat2) / 2;
        navigation.mapCenter.lng = (maxDistance.lng1 + maxDistance.lng2) / 2;
        
        // navigation.mapZoom = Math.round(Math.log2(2045 * Math.PI / angle(maxDistance.lng1, maxDistance.lng2)));



        let distanceXY = distance(maxDistance.lat1,maxDistance.lng1,maxDistance.lat2, maxDistance.lng2);
        if (distanceXY > 100) navigation.mapZoom = Math.log2(156543.03392 * 400 / distanceXY); else navigation.mapZoom = 13;
       

    }    
    else if (data.mapPois.length > 0) {
        
        
        navigation.mapCenter.lat = parseFloat(data.mapPois[0].objLat);
        navigation.mapCenter.lng = parseFloat(data.mapPois[0].objLng);
        navigation.mapZoom = 13;
        
    }
    
}

var GLOBE_WIDTH = 256; // a constant in Google's map projection
var west = sw.lng();
var east = ne.lng();
var angle = east - west;
if (angle < 0) {
  angle += 360;
}
var zoom = Math.round(Math.log(pixelWidth * 360 / angle / GLOBE_WIDTH) / Math.LN2);
*/


function objLevelAngleCount(listObjRel, objRel) {
    if (listObjRel.length === 0) listObjRel.push({ objLevel: objRel.objLevel, angle: objRel.angle,  childs: [objRel] });
    else {
        let findObjRel = listObjRel.find(i => i.objLevel === objRel.objLevel && i.angle === objRel.angle);
        if (findObjRel === undefined) listObjRel.push({ objLevel: objRel.objLevel, angle: objRel.angle,  childs: [objRel] });
        else findObjRel.childs.push(objRel);
    }
    return listObjRel; 
}

function objLevelCount(listObjRel, objRel) {
    if (listObjRel.length === 0) listObjRel.push({ objLevel: objRel.objLevel, childs: [objRel] });
    else {
        let findObjRel = listObjRel.find(i => i.objLevel === objRel.objLevel);
        if (findObjRel === undefined) listObjRel.push({ objLevel: objRel.objLevel, childs: [objRel] });
        else findObjRel.childs.push(objRel);
    }
    return listObjRel; 
}

function assignChildAngle(objRel) {
    if (objRel.parent !== null) objRel.angle = objRel.parent.angle;
    if (objRel.childs !== undefined) objRel.childs.forEach(objRelChi => assignChildAngle(objRelChi));
}

function assignChildLevel(objRel) {
    if (objRel.parent !== null) objRel.objLevel = objRel.parent.objLevel + 1;
    if (objRel.childs !== undefined) objRel.childs.forEach(objRelChi => assignChildLevel(objRelChi));
}


function assignChildsRel(objRel, index, num) {
    if (objRel.parent !== null) { 
        objRel.radius = objRel.parent.radius * objRel.objLevel / objRel.parent.objLevel;
        objRel.angle = objRel.parent.angle + (80 / objRel.radius) * (index - num/2 + 0.5)

    }
    if (objRel.childs !== undefined) objRel.childs.forEach((objRelChi, index) => assignChildsRel(objRelChi, index, objRel.childs.length));
}

function calcBoundries(b,a) {
    return (
    { left: (a.x <= b.left) ? a.x : b.left,
      top: (a.y <= b.top) ? a.y : b.top,
      right: (a.x >= b.right) ? a.x : b.right,
      bottom: (a.y >= b.bottom) ? a.y : b.bottom } )
}


function calcRelationMapSize(objRelated, mapRelation) {
    let mapBoundries = objRelated.filter(objRel => objRel.shown).reduce(calcBoundries, { left: 1000, top: 1000, right: -1000, bottom: -1000 });
    mapRelation.width = mapBoundries.right - mapBoundries.left + 100;
    mapRelation.height = mapBoundries.bottom - mapBoundries.top + 100;
    mapRelation.x = -(mapBoundries.left - 50);
    mapRelation.y = -(mapBoundries.top - 50);
}


function objLevelGroups(listObjRel, objRel) {
    if (listObjRel.length === 0) listObjRel.push({ objTypeId: objRel.objTypeId, childs: [objRel] });
    else {
        let findObjRel = listObjRel.find(i => i.objTypeId === objRel.objTypeId);
        if (findObjRel === undefined) listObjRel.push({ objTypeId: objRel.objTypeId, childs: [objRel] });
        else findObjRel.childs.push(objRel);
    }
    return listObjRel; 
}

function calcRelationGroups(objRelated, user, config) {
    let listToRemove = objRelated.filter(objRel => objRel.objChild === user.userId && objRel.objChildTypeId === 1 && objRel.objTypeId !== 1 && !objRel.childs);
    listToRemove.forEach(objRel => objRelated.splice(objRelated.indexOf(objRel),1));
    let listObjRelGroups = objRelated.filter(objRel => objRel.objChild === user.userId && objRel.objChildTypeId === 1 && objRel.objTypeId !== 1 && objRel.childs).reduce(objLevelGroups, []);
    listObjRelGroups.forEach(objRelGroup => {
        let objType = config.objTypes.find(objType => objType.objTypeId === objRelGroup.objTypeId);
        let newObjRel = { objTypeId : objRelGroup.objTypeId, isGroup: true, objId: null, objType: objType, objName: "", objSvg: objType.objTypeSvg, objProfile: null, attRelated: 1, objLevel: 1, objChild: user.userId, 
            objChildTypeId: 1, childs: objRelGroup.childs, angle: objRelGroup.childs[0].angle, radius: 40 + (1) * 80, parent: null, att: objRelGroup.childs[0].att  }
        objRelated.push(newObjRel);
        newObjRel.childs.forEach(objRelChi => objRelChi.parent = newObjRel);
        assignChildLevel(newObjRel);
    })
}

function calcRelationMap(globalState) {
    const { config, user, data, navigation } = globalState;
    const { objRelated } =  data;


    objRelated.forEach(objRel => { 
        let att = objRel.objRel.split('-');
        objRel.objChild = parseInt(att[3]);
        objRel.objChildTypeId = parseInt(att[2]);
        objRel.attId = parseInt(att[5]);
        objRel.objLevel = parseInt(att[1]);
        let findAtt = config.attributes.find(att => att.objTypeId === objRel.objTypeId && att.attId === objRel.attId);
        if (findAtt !== undefined) objRel.att = findAtt; 
        let findObjRel = objRelated.find(parentRel => parentRel.objId === objRel.objChild && parentRel.objTypeId === objRel.objChildTypeId);
        if (findObjRel !== undefined) { objRel.parent = findObjRel; if (findObjRel.childs === undefined) findObjRel.childs = [objRel]; else findObjRel.childs.push(objRel)}
        else objRel.parent = null; 
        if (objRel.objChild === user.userId && objRel.objChildTypeId === 1) objRel.angle =  objRel.att.attDirection * Math.PI / 180; else objRel.angle = 0;
        objRel.radius = 40 + (objRel.objLevel) * 80;      
    })   
    calcRelationGroups(objRelated, user, config)
    /* let maxLevel = objRelated.reduce((a, b) => (a.objLevel > b.objLevel ? { objLevel: a.objLevel } : { objLevel: b.objLevel }), 0).objLevel;
    objRelated.filter(objRel => objRel.objTypeId !== 1).forEach(objRel => { objRel.objLevel = maxLevel; assignChildLevel(objRel)}); */
    objRelated.filter(objRel => objRel.parent === null).forEach(objRel => { assignChildAngle(objRel)});
    let listObjRel = objRelated.reduce(objLevelCount, []).sort((a,b) => a.objLevel - b.objLevel);

    let listObjRelAngle = listObjRel[0].childs.reduce(objLevelAngleCount, []);
    let maxCount = listObjRelAngle.reduce((a,b) => (a.angleCount > b.childs.length ? { angleCount: a.angleCount } : { angleCount: b.childs.length }), 0).angleCount;
    if (maxCount < 3) maxCount = 3;
    let radius = maxCount * 160 / Math.PI;
    listObjRel[0].childs.forEach(objRelLevel => objRelLevel.radius = radius);
    
    listObjRelAngle.forEach(objRelLevel => { 
        if (objRelLevel.childs !== undefined) { 
            let numChilds = objRelLevel.childs.length;
            objRelLevel.childs.forEach((objRel, index) => { 
                objRel.angle = objRel.angle + (80 / objRel.radius) * (index - numChilds/2 + 0.5);
                objRel.showChilds = false;
                assignChildsRel(objRel,0,0);
             }) 
        }
    })
    objRelated.forEach(objRel => { 
        objRel.x = objRel.radius * Math.sin(objRel.angle); 
        objRel.y = objRel.radius * Math.cos(objRel.angle); 
        objRel.shown = (objRel.objLevel === 1);
    })    
    calcRelationMapSize(objRelated, navigation.mapRelation) 
}



export { structAttributes, addObject, addNewObject, closeObject, hideObjects, updateObject, updateListParent, setHeaderColumns, structSearch, filterSearch, structMap, structConnections,
         updateObjectInst, pushObjChilds, updateObjChilds, updateObjectDependentValues, updateObjectFunctionValues, updateObjectValueNames, updateObjectNullAttributes, structObject, structObjectHeader, structObjProfile, addObjectValueInst, updateAttFileValue,
         cloneObjAttVal, isAttEditable, deleteObjectValueInst, deleteObjChilds, purgeObjAttValues, filterObjCardAttVal, calcRelationMap, calcRelationMapSize, valueChanged, assignObjAttValue };
  