import React, { useContext, useState } from 'react';
import { requestPassword } from './PopApiGlobal';
import { requestSession } from './PopApiUser';
import Context from './Context';
import { PopSvg, popCaption } from './PopSvg';


function PopLogin() {
    const { globalState, globalDispatch } = useContext(Context);
    const { config, user, navigation } = globalState;
    const [login, setLogin] = useState('');
    const [password, setPassword] = useState('');
    const [vCode, setVCode] = useState('');


    const onSubmit = e => {
        e.preventDefault();
        requestSession(login, password, vCode, navigation.langId, globalState, globalDispatch);
    }

    const onClick = e => {
        e.preventDefault();
        requestPassword(navigation.langId, login, globalState, globalDispatch);
    }

    return (
        <div className = "pop-login-box">
            <div className = "pop-login-content-box">
                <div className = "pop-login-card-box" >
                    <div className = "pop-login-card-content">
                        <div className = "pop-login-card-header">
                            <PopSvg svg="locked"/>
                            <h3>{popCaption(config, "please-login").captionLong}</h3>      
                        </div>
                        <form className = "pop-login-fields" onSubmit={onSubmit}>
                            <div className = "pop-login-field">
                                <div className = "pop-login-field-label">
                                    <label htmlFor = "login" >{popCaption(config, "email").captionLong}</label>
                                </div>
                                <div className = "pop-login-field-value">
                                    <input id = "login" type = "text" autoFocus name = "username" value = { login } onChange = { o => setLogin(o.target.value) }/> 
                                </div>                        
                            </div>
                            <div className = "pop-login-field">
                                <div className = "pop-login-field-label">
                                    <label htmlFor = "password" >{popCaption(config, "password").captionLong}</label>
                                </div>
                                <div className = "pop-login-field-value">
                                    <input id = "password" type = "password" name = "password" value = { password } onChange = { o => setPassword(o.target.value) }/> 
                                    <button className="pop-login-field-icon">
                                        <PopSvg svg="return"/>
                                    </button>      
                                </div>                        
                            </div> 
                            { user.validLogin && (user.verifiedEmail === false) &&
                            <div className = "pop-login-field">
                                <div className = "pop-login-field-label">
                                    <label htmlFor = "vcode" >{popCaption(config, "code").captionLong}</label>
                                </div>
                                <div className = "pop-login-field-value">
                                    <input id = "vcode" type = "code" name = "code" value = { vCode } onChange = { o => setVCode(o.target.value) }/> 
                                </div>                        
                            </div> }
                            { user.validEmail && (user.validLogin === false) && 
                            <div className = "pop-login-field">
                                <div className = "pop-login-field-label">
                                    <label style={{"text-decoration": "underline"}} onClick={onClick}>{popCaption(config, "change-pass-request").captionLong}</label>
                                </div>
                            </div> }
                        </form>
                    </div>
                </div>
            </div>
        </div> 
    );
}

export default PopLogin;