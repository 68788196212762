import React, { useContext, useEffect } from 'react';
import Context from './Context';
import { BrowserRouter as Router, Routes, Route, Navigate, useParams  } from "react-router-dom";
import { confirmRequest } from './PopApiGlobal';
import { confirmSession, selectLocation, selectConfig } from './PopApiUser';


import './App.css';
import './PopGrid.css' 
import './PopHeader.css';
import './PopFooter.css';
import './PopMenu.css'; 
import './PopFolders.css'; 
import './PopLogin.css'; 
import './PopToolBox.css'; 
import './PopMessageBox.css'; 
import './PopSearch.css'; 
import './PopConnect.css';
import './PopObject.css'; 
import './PopAttribute.css';
import './PopMap.css';
import './PopKpi.css';
import './PopRelation.css';
import './PopMedia.css';

import PopHeader from './PopHeader';
import PopFooter from './PopFooter';
import PopMenu from './PopMenu'; 
import PopLogin from './PopLogin';
import { PopMessage, PopEmail, PopPassword, PopRegister, PopJoin, PopAccess, PopDelete } from './PopUser';
import PopToolBox from './PopToolBox';
import PopMessageBox from './PopMessageBox';
import PopSearch from './PopSearch';
import PopObjects from './PopObject';
import GlobalStateProvider from './GlobalStateProvider';

function App() {
  return (
    <GlobalStateProvider>
    <Router>
    <div>
      <Routes>
        <Route path="/" element={<MainPage/>}/>
        <Route path="login" element={<LoginPage/>}/>
        <Route path="request/:requestType/:langId/:requestKey" element={<RequestPage/>}/>
        <Route path=":objLink" element={<LinkPage/>}/>
      </Routes>     
    </div>
    </Router>
  </GlobalStateProvider>
  );
}

function LoginPage() {
  const { globalState, globalDispatch } = useContext(Context);
 
  useEffect(() => { 
    if (navigator.geolocation && !globalState.navigation.currentlocation.checked) selectLocation(globalState, globalDispatch);
    if (!globalState.config.configLoaded) selectConfig(null, globalState, globalDispatch); 
    if (globalState.config.configLoaded && !globalState.user.userLogged) confirmSession(globalState, globalDispatch); })
  
  return (  
    globalState.user.userLogged ? <Navigate  to="/" /> :
    globalState.config.configLoaded ?
    <div className="pop-app">
      <div className="pop-upper">
      <header>
        <PopHeader/>
        <PopMenu/> 
      </header>     
      <main className = "pop-main">
        <PopLogin/>
      </main>
      </div>
      <footer>
        <PopFooter/>
      </footer>      
    </div> :  null
     ) 
}


function RequestPage() {
  const { globalState, globalDispatch } = useContext(Context);
  const { user, config } = globalState;
  const { requestType, requestKey, langId } = useParams();
 
  
  useEffect(() => { 
    if (navigator.geolocation && !globalState.navigation.currentlocation.checked) selectLocation(globalState, globalDispatch);
    if (!config.configLoaded) selectConfig(langId, globalState, globalDispatch);
    if (config.configLoaded && user.requestConfirmed === null) confirmRequest(requestType, requestKey, globalState, globalDispatch);  })
  
  return (  
    globalState.user.userLogged ? <Navigate  to="/" /> :
    config.configLoaded && (user.requestConfirmed !== null) ?
      <div className="pop-app">
        <div className="pop-upper">
        <header>
           <PopHeader/>
           <PopMenu/> 
        </header>     
        <main className = "pop-main">
        { !user.requestConfirmed ? <PopMessage msg="invalid-link"/> :
        {
        'register': <PopRegister/>,
        'join':    <PopJoin/>,
        'access':   <PopAccess/>,
        'email':    <PopEmail/>,
        'password': <PopPassword/>,
        'delete':   <PopDelete/>
        }[requestType] }
        </main>
        </div>
        <footer>
          <PopFooter/>
        </footer>      
      </div> : null
     ) 
}




function LinkPage() {
  const { globalState, globalDispatch } = useContext(Context);
  const { navigation, user } = globalState;
  const { objLink } = useParams();

  useEffect(() => { 
    if (navigation.linkToLoad === null) { navigation.linkToLoad = objLink; globalDispatch( { type: 'update-state', state: globalState  }); }
    })


  return (
    !user.userLogged ? <Navigate  to="/login" /> : <Navigate  to="/" /> 
  );

}


function MainPage() {
  const { globalState } = useContext(Context);
  const { navigation, user } = globalState;

  return (
    !user.userLogged ? <Navigate  to="/login" /> :
    globalState.config.configLoaded ?
    <div className="pop-app">
      <div className="pop-upper">
      <header className="pop-header">
        <PopHeader/>
        <PopMenu/> 
      </header>    
      <main className = { navigation.showSearchDetail && navigation.showSearchSide ? "pop-main-grid" : "pop-main" }>
        { navigation.showSearch && <PopSearch/> }
        <PopObjects/>
        { navigation.editMode && <PopToolBox/> }
        { navigation.showMessage && <PopMessageBox/> }
      </main>  
      </div>
      <footer className="pop-footer">
        <PopFooter/>
      </footer>
    </div> : null
  );

}


export default App;
