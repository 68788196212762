// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pop-map {
    background-color: #f0efeb;
    box-shadow: 2px 2px 2px lightgrey;
    margin-bottom: 6px;
}


.pop-map-content {
    padding: 0px;
    height: 85vh;
    width: 100%;
}


.pop-map-header {
    background:	#78ACDC;	
    height: 30px;
    display: flex;   
    align-items: center;
    padding: 0px;
}

.pop-map-header h3 {
    text-align: left;
    vertical-align: middle;
    font-weight: var(--font-bold);
    font-size: 16px;
    color: white;
    padding-left: 5px;
    flex-basis: 100%;
}


.pop-map-header svg {
    fill: #fff;
    height: 30px;
    min-width: 30px;
    margin: 0 3px 0 3px;
   }


.pop-map-header img {
 /*   flex-basis: 5%; */
    padding: 0 5px 0 5px;
    height: 20px;
}

.pop-map-header img:hover {
    opacity: 0.5;
}

.pop-map-marker {
    height: 50px;
    width: 50px;    
}

.pop-map-marker-new {
    height: 50px;
    width: 50px;    
}

.pop-map-marker-location {
    height: 30px;
    width: 30px;    
}

`, "",{"version":3,"sources":["webpack://./src/PopMap.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,iCAAiC;IACjC,kBAAkB;AACtB;;;AAGA;IACI,YAAY;IACZ,YAAY;IACZ,WAAW;AACf;;;AAGA;IACI,mBAAmB;IACnB,YAAY;IACZ,aAAa;IACb,mBAAmB;IACnB,YAAY;AAChB;;AAEA;IACI,gBAAgB;IAChB,sBAAsB;IACtB,6BAA6B;IAC7B,eAAe;IACf,YAAY;IACZ,iBAAiB;IACjB,gBAAgB;AACpB;;;AAGA;IACI,UAAU;IACV,YAAY;IACZ,eAAe;IACf,mBAAmB;GACpB;;;AAGH;CACC,sBAAsB;IACnB,oBAAoB;IACpB,YAAY;AAChB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,YAAY;IACZ,WAAW;AACf;;AAEA;IACI,YAAY;IACZ,WAAW;AACf;;AAEA;IACI,YAAY;IACZ,WAAW;AACf","sourcesContent":[".pop-map {\r\n    background-color: #f0efeb;\r\n    box-shadow: 2px 2px 2px lightgrey;\r\n    margin-bottom: 6px;\r\n}\r\n\r\n\r\n.pop-map-content {\r\n    padding: 0px;\r\n    height: 85vh;\r\n    width: 100%;\r\n}\r\n\r\n\r\n.pop-map-header {\r\n    background:\t#78ACDC;\t\r\n    height: 30px;\r\n    display: flex;   \r\n    align-items: center;\r\n    padding: 0px;\r\n}\r\n\r\n.pop-map-header h3 {\r\n    text-align: left;\r\n    vertical-align: middle;\r\n    font-weight: var(--font-bold);\r\n    font-size: 16px;\r\n    color: white;\r\n    padding-left: 5px;\r\n    flex-basis: 100%;\r\n}\r\n\r\n\r\n.pop-map-header svg {\r\n    fill: #fff;\r\n    height: 30px;\r\n    min-width: 30px;\r\n    margin: 0 3px 0 3px;\r\n   }\r\n\r\n\r\n.pop-map-header img {\r\n /*   flex-basis: 5%; */\r\n    padding: 0 5px 0 5px;\r\n    height: 20px;\r\n}\r\n\r\n.pop-map-header img:hover {\r\n    opacity: 0.5;\r\n}\r\n\r\n.pop-map-marker {\r\n    height: 50px;\r\n    width: 50px;    \r\n}\r\n\r\n.pop-map-marker-new {\r\n    height: 50px;\r\n    width: 50px;    \r\n}\r\n\r\n.pop-map-marker-location {\r\n    height: 30px;\r\n    width: 30px;    \r\n}\r\n\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
