// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.pop-footer-box {
    align-self: flex-end;
    background-color: #606060;;
    height: 25px;
    display: flex;
    flex-direction: row;
    align-items:center;
    justify-content: space-between;
    color: white;
    padding: 0 16px 0 16px;
  }

  .pop-footer-title {
    flex-basis: 100%;
    display: flex;
    align-items: center;
  }
 
  
  .pop-footer-title h4 {
    font-weight: var(--font-bold);
    font-size: 12px;  
  }
  
`, "",{"version":3,"sources":["webpack://./src/PopFooter.css"],"names":[],"mappings":";AACA;IACI,oBAAoB;IACpB,yBAAyB;IACzB,YAAY;IACZ,aAAa;IACb,mBAAmB;IACnB,kBAAkB;IAClB,8BAA8B;IAC9B,YAAY;IACZ,sBAAsB;EACxB;;EAEA;IACE,gBAAgB;IAChB,aAAa;IACb,mBAAmB;EACrB;;;EAGA;IACE,6BAA6B;IAC7B,eAAe;EACjB","sourcesContent":["\r\n.pop-footer-box {\r\n    align-self: flex-end;\r\n    background-color: #606060;;\r\n    height: 25px;\r\n    display: flex;\r\n    flex-direction: row;\r\n    align-items:center;\r\n    justify-content: space-between;\r\n    color: white;\r\n    padding: 0 16px 0 16px;\r\n  }\r\n\r\n  .pop-footer-title {\r\n    flex-basis: 100%;\r\n    display: flex;\r\n    align-items: center;\r\n  }\r\n \r\n  \r\n  .pop-footer-title h4 {\r\n    font-weight: var(--font-bold);\r\n    font-size: 12px;  \r\n  }\r\n  \r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
