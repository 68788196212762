import React, { useContext, useState } from 'react';
import Context from './Context';
import { PopSvg, popCaption } from './PopSvg';


function changeWny(value) {
    switch (value) {
        case "week": return("month"); 
        case "month": return("year"); 
        default: return("week"); 
    }
}

function changeRelationLevel(value) {
    switch (value) {
        case 1: return(2); 
        case 2: return(3); 
        case 3: return(null); 
        default: return(1); 
    } 
    
}

function relationIcon(value) {
    switch (value) {
        case 1: return("group-1"); 
        case 2: return("group-2"); 
        case 3: return("group-3"); 
        default: return("related"); 
    }   
}


function PopChartManagedObjects(props) {
    const {  globalState } = useContext(Context);
    const { config, data } = globalState;
    const [wmy, setWmy] = useState("week");


    return (
    <div className="pop-chart-card">
        <div className="pop-chart-card-header">
            <PopSvg svg="settings"/>
            <h3>{popCaption(config, "kpi-obj-managed").captionLong}</h3>
            <PopSvg svg="time"/>
            <p onClick= {() => setWmy(changeWny(wmy))}>{popCaption(config, wmy).captionLong}</p>
        </div>
        <div className="pop-card-fields-scroll">
            <div className="pop-card-fields">
            <div className="pop-chart-kpi-header">
                <div className="pop-chart-kpi-icon-header"><PopSvg fill="#fff" svg="empty"/></div>
                <div className="pop-chart-kpi-number-big"><label>{popCaption(config, "kpi-tot").captionLong}</label></div>
                <div className="pop-chart-kpi-number-small"><label>{popCaption(config, "kpi-chg").captionLong}</label></div>  
                <div className="pop-chart-kpi-number-small"><label>{popCaption(config, "kpi-new").captionLong}</label></div>  
            </div>
                { data.kpis.filter(kpi => kpi.chart === 0).map((kpi, index) => {return(
                    <div className="pop-chart-kpi" id={index}>
                    <div className="pop-chart-kpi-icon">
                        <PopSvg svg={kpi.svg}/>
                        <label>{kpi.name}</label>
                    </div>
                    <div className="pop-chart-kpi-number-big">
                       <p>{kpi.count}</p>
                    </div>                                      
                    <div className="pop-chart-kpi-number-small">
                       <p>{kpi[wmy+"Chg"]}</p>
                    </div>                               
                    <div className="pop-chart-kpi-number-small">
                       <p>{kpi[wmy+"New"]}</p>
                    </div>                            
                </div>  
                )}) }      
            </div>
        </div>
    </div>);   
}

function PopChartRelatedObjects(props) {
    const {  globalState } = useContext(Context);
    const { config, data } = globalState;
    const [wmy, setWmy] = useState("week");
    const [relationLevel, setRelationLevel] = useState(null);


    return (
    <div className="pop-chart-card">
        <div className="pop-chart-card-header">
            <PopSvg svg="related"/>
            <h3>{popCaption(config, "kpi-obj-related").captionLong}</h3>
            <PopSvg svg={relationIcon(relationLevel)} onClick={() => setRelationLevel(changeRelationLevel(relationLevel))}/>
            <PopSvg svg="time"/>
            <p onClick= {() => setWmy(changeWny(wmy))}>{popCaption(config, wmy).captionLong}</p>
        </div>
        <div className="pop-card-fields-scroll">
            <div className="pop-card-fields">
            <div className="pop-chart-kpi-header">
                <div className="pop-chart-kpi-icon-header"><PopSvg fill="#fff" svg="empty"/></div>
                <div className="pop-chart-kpi-number-big"><label>{popCaption(config, "kpi-tot").captionLong}</label></div>
                <div className="pop-chart-kpi-number-small"><label>{popCaption(config, "kpi-chg").captionLong}</label></div>  
                <div className="pop-chart-kpi-number-small"><label>{popCaption(config, "kpi-new").captionLong}</label></div>  
            </div>
                { data.kpis.filter(kpi => kpi.chart === 1 && kpi.relationLevel === relationLevel).map((kpi, index) => {return(
                    <div className="pop-chart-kpi" id={index}>
                    <div className="pop-chart-kpi-icon">
                        <PopSvg svg={kpi.svg}/>
                        <label>{kpi.name}</label>
                    </div>
                    <div className="pop-chart-kpi-number-big">
                       <p>{kpi.count}</p>
                    </div>                                      
                    <div className="pop-chart-kpi-number-small">
                       <p>{kpi[wmy+"Chg"]}</p>
                    </div>                               
                    <div className="pop-chart-kpi-number-small">
                       <p>{kpi[wmy+"New"]}</p>
                    </div>                            
                </div>  
                )}) }      
            </div>
        </div>
    </div>);   
}




function chartCardClass(navigation) {
    if (navigation.showSearchDetail) return "pop-chart-cards-main-grid";
    return "pop-chart-cards";
}


function PopKPI() {
    const { globalState, globalDispatch } = useContext(Context);
    const { navigation, config } = globalState;
   

    return (
        navigation.showKpi ?
        <div className = "pop-chart">
            <div className = "pop-chart-header">
                <PopSvg svg="kpi"/>
                <h3>{popCaption(config,'kpi').captionLong}</h3>
                <PopSvg svg={navigation.showKpiDetail ? "hide": "show"} onClick= {() => { navigation.showKpiDetail = !navigation.showKpiDetail; globalDispatch( { type: 'update-state', state: globalState });}}/>
                <PopSvg svg="close" onClick = {() => { navigation.showKpi = false; navigation.showKpiDetail = false; globalDispatch( { type: 'update-state', state: globalState });}}/>
            </div>                     
            { navigation.showKpiDetail && 
            <div className = "pop-chart-content" key={navigation.chartUpdate}>    
                <div className={ chartCardClass(navigation) }>
                    <PopChartManagedObjects/>
                    <PopChartRelatedObjects/>
                </div>
            </div>  }
        </div> : null
    );
}


export default PopKPI;
